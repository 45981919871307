import VuexORM from '@vuex-orm/core';
import {
  BenchTag,
  ConnectedTag,
  League,
  Lineup,
  LineupTag,
  Media,
  Organization,
  PlayerSubstitution,
  PositionSwitch,
  Result,
  Role,
  Roster,
  RosterPlayer,
  Season,
  Session,
  SessionV1,
  Team,
  User,
  FormattedResult,
} from '@/models/orm/Hierarchy';
import teamModule from '../modules/teams';
import connectedTagsModule from '../modules/connectedTags';

// Create a new instance of Database.
export const database = new VuexORM.Database();

// Register Models to Database.
database.register(BenchTag);
database.register(ConnectedTag, connectedTagsModule);
database.register(League);
database.register(Lineup);
database.register(LineupTag);
database.register(Media);
database.register(Organization);
database.register(PlayerSubstitution);
database.register(PositionSwitch);
database.register(FormattedResult);
database.register(Result);
database.register(Role);
database.register(Roster);
database.register(RosterPlayer);
database.register(Season);
database.register(Session);
database.register(SessionV1);
database.register(Team, teamModule);
database.register(User);

export default VuexORM.install(database);
