import Vue from 'vue';
import { Client, Store } from '@/services';
import { Team } from '@/models/orm/Hierarchy';
import { Services } from '@/store/actions/api';
import STATES from '@/store/actions/states';
import generateTeam from '@/utils/TeamGenerator';
import { captureException } from '@sentry/vue';

const RequestMap = new Map();

export default {
  async fetchTeam(id) {
    try {
      let params = {
        service: Services.TeamManagement.key,
        method: Services.TeamManagement.methods.GetTeam.key,
        ...Services.TeamManagement.methods.GetTeam.query,
      };

      params.TeamID = id;

      const { data: fromApi } = await Client({ params });

      if (fromApi?.id) {
        let injected = await Team.insertOrUpdate({ data: [fromApi] });

        const fromOrm = this.getSingle(id);

        return { team: fromOrm };
      }
    } catch (error) {
      captureException(error);
      Vue.$log.debug('Error when retrieving team ', id);
      return { error: `Error when retrieving team ${id}` };
    }
  },

  async listPublic(seasonId, force) {
    if (!Number(seasonId)) {
      throw new TypeError('Season id is not a valid number');
    }

    if (!force) {
      const publicTeams = Team.getters('publicTeamsForSeason')(seasonId);

      if (publicTeams.length) {
        return publicTeams;
      }
    }

    await Team.dispatch('setFetched', false);
    await Team.dispatch('setFetching', true);

    let startIndex = 0;
    let totalRows = 1;
    const count = 1000;
    let teamsArray = [];
    while (startIndex < totalRows) {
      try {
        const params = {
          service: Services.TeamManagement.key,
          method: Services.TeamManagement.methods.GetPublicTeams.key,
          StartIndex: startIndex,
          Count: count,
          Search: '',
          // IsOfficial,
          // LeagueID,
          SeasonID: seasonId
        };

        // eslint-disable-next-line no-await-in-loop
        const { data } = await Client({ params });

        const { pagination, teams } = data;

        teamsArray = [].concat(teamsArray, teams);
        // eslint-disable-next-line no-await-in-loop

        ({ totalRows } = pagination);
        startIndex += count;
      } catch (error) {
        captureException(error);
        Vue.$log.debug(error);
        break;
      }
    }
    await Team.insertOrUpdate({ data: teamsArray });
    await Team.dispatch('setFetching', false);
    await Team.dispatch('setFetched', true);

    return Team.getters('publicTeamsForSeason')(seasonId);
  },

  async listFollowable(seasonId) {
    if (!Number(seasonId)) {
      throw new TypeError('Season id is not a valid number');
    }

    let followableTeams = Team.query().where((team) => {
      return team.seasonID === seasonId && team.followable;
    }).get();

    if (followableTeams.length) {
      return followableTeams;
    }

    const pubTeams = await this.listPublic(seasonId, true);

    followableTeams = Team.query().where((team) => {
      return team.seasonID === seasonId && team.followable;
    }).get();

    return followableTeams;
  },

  async getManaged(id) {
    await Team.dispatch('setFetching', true);

    let startIndex = 0;
    const count = 1000;

    try {
      const params = {
        service: Services.TeamManagement.key,
        method: Services.TeamManagement.methods.GetTeams.key,
        // SeasonID,
      };

      if (id && Number(id)) {
        params.SeasonID = id;
      }

      const response = await Client({ params });

      const {
        teams,
        // TODO: analyze if its still needed here
        createdByUser,
        coachForTeams,
        scorekeeperForTeams,
        accessAsManager,
        connectedRosterTags
      } = response.data;

      if (teams) {
        await Team.insertOrUpdate({ data: teams });
      }

      await Team.dispatch('setFetchedManaged', true);
      return {
        createdByUser,
        coachForTeams,
        scorekeeperForTeams,
        accessAsManager,
        connectedRosterTags,
      };
    } catch (error) {
      captureException(error);
      Vue.$log.debug(error);
      return null;
    } finally {
      await Team.dispatch('setFetching', false);
    }
  },

  getSingle(id) {
    return Team.query().where('id', id).withAll().first();
  },

  async get(id, force) {
    if (!id) {
      throw TypeError('team ID needs to be an positive integer.');
    }
    const team = this.getSingle(id);

    // use cached value or existing request
    if (!force) {
      // get from ORM
      if (team) {
        if (RequestMap.has(id)) {
          RequestMap.delete(id);
        }
        return { team };
      }

      if (RequestMap.has(id)) {
        // get existing request
        return RequestMap.get(id);
      }
    }

    // save request and return
    RequestMap.set(id, this.fetchTeam(id));

    return RequestMap.get(id);
  },

  async getTeam(id) {
    if (!(Store.state.entities.teams.fetchedManaged && Store.state.user.status === STATES.SUCCESS)) {
      throw new Error('Check and wait for allTeamsLoaded first');
    }
    if (!Number(id)) {
      Vue.$log.debug('Using generated team', id);
      return generateTeam(id);
    }
    let team = Team.find(id);

    if (!team) {
      // get from API
      try {
        const response = await Client({
          params: {
            service: Services.TeamManagement.key,
            method: Services.TeamManagement.methods.GetTeam.key,
            TeamID: id,
          },
        });

        team = await Team.insertOrUpdate({ data: response.data });
      } catch (error) {
        captureException(error);
        Vue.$log.error(error);
      }
    }

    if (!team) {
      // else return generated
      Vue.$log.debug('Using generated team', id);
      return generateTeam(id);
    }

    return team;
  },
};
