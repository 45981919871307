import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';

// enable when ready
import { vuexCookie } from './persist';
import { IS_PRODUCTION } from '../constants';
import alerts from './modules/alerts';
import api from './modules/api';
import app from './modules/app';
import auth from './modules/auth';
import user from './modules/user';
import sessions from './modules/sessions';
import STATES from './actions/states';

import orm from './orm';

Vue.use(Vuex);

const getters = {
  allTeamsLoaded: (state) => !state.entities.teams.fetching && state.entities.teams.fetchedManaged && state.user.status === STATES.SUCCESS,
};

export const Store = new Vuex.Store({
  strict: IS_PRODUCTION,
  modules: {
    alerts,
    api,
    app,
    auth,
    user,
    sessions,
  },
  plugins: [
    // vuexLocal.plugin,
    vuexCookie.plugin,
    pathify.plugin,
    // Create Vuex Store and register database through Vuex ORM.
    orm,
  ],
  getters,
});

export default Store;
