import PlayerRepository from './PlayerRepository';
import TeamRepository from './TeamRepository';
import ConnectedTagRepository from './ConnectedTagRepository';
import SessionRepository from './SessionRepository';
import RosterRepository from './RosterRepository';
import MediaRepository from './MediaRepository';

const repositories = {
  players: PlayerRepository,
  teams: TeamRepository,
  connectedTags: ConnectedTagRepository,
  sessions: SessionRepository,
  rosters: RosterRepository,
  media: MediaRepository,
};
export default {
  get: (name) => repositories[name],
};
