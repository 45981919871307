import Vue from 'vue';

import { VueReCaptcha } from 'vue-recaptcha-v3';

export default function useRecaptcha() {
  Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true,
    },
  });
}
