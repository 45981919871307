import Vue from 'vue';
import { Client, Store, router } from '@/services';

import STATES from '../actions/states';
import { Services } from '../actions/api';
import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_CLEAN,
  IS_AUTHENTICATED,
  M_UNAUTHORIZE,
  POST_AUTHORIZATION,
  UNAUTHORIZE,
} from '../actions/auth';

import { USER_CLEAN, USER_REQUEST, USER_ABILITIES_UPDATE, USER_LEAGUE_REQUEST } from '../actions/user';
import { SESSIONS_FILTER_CLEAN } from '../actions/sessions';
import { M_RESET_ALERTS } from '../actions/alerts';
import { PLAYERS_FILTER_CLEAN } from '../actions/players';
import { captureException, setUser } from '@sentry/vue';

const GETTERS = {
  authStatus: state => state.status,
  isAuthorizing: state => state.status === STATES.LOADING,
  isAuthorized: state => state.status === STATES.AUTHORIZED,
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, payload) => new Promise((resolve, reject) => {
    commit(AUTH_REQUEST);
    commit(M_RESET_ALERTS, undefined, { root: true });

    const { errorHandler, ...data } = payload;

    const config = {
      params: {
        service: Services.Authentication.key,
        method: Services.Authentication.methods.TryAuthenticate.key,
      },
      errorHandler,
      data,
      method: 'post',
    };

    Client(config)
      .then((resp) => {
        commit(AUTH_SUCCESS, resp);
        dispatch(POST_AUTHORIZATION).then(() => resolve(resp));
      })
      .catch((err) => {
        commit(AUTH_ERROR, err);
        commit(AUTH_LOGOUT);
        reject(err);
      });
  }),

  [AUTH_LOGOUT]: async ({ dispatch }) => {
    let loggedOut = false;
    try {
      loggedOut = await Client(
        {
          params: {
            service: Services.Authentication.key,
            method: Services.Authentication.methods.LogOutUser.key,
          },
          method: 'post',
          coreHandler: 'skip',
        }
      );
    } catch (error) {
      captureException(error);
      Vue.$log.debug(error);
    } finally {
      await dispatch(UNAUTHORIZE);
    }
  },

  [AUTH_CLEAN]: ({ dispatch }) => {
    let promises = [
      dispatch(SESSIONS_FILTER_CLEAN, null, { root: true }),
      dispatch(USER_CLEAN, null, { root: true }),
    ];

    if (Store.hasModule('players')) {
      promises.push(dispatch(`players/${PLAYERS_FILTER_CLEAN}`, null, { root: true }));
    }

    return Promise.all(promises);
  },

  [IS_AUTHENTICATED]: ({ commit, dispatch }) => new Promise((resolve, reject) => {
    Client({
      params: {
        service: Services.Authentication.key,
        method: Services.Authentication.methods.IsUserLoggedIn.key,
      },
    }).then(
      (resp) => {
        if (resp.request.responseText === 'true') {
          commit(AUTH_SUCCESS);
          dispatch(POST_AUTHORIZATION).then(() => resolve(true));
        } else {
          commit(AUTH_ERROR);
          dispatch(UNAUTHORIZE).then(() => resolve(false));
        }
      },
      (resp) => reject(resp),
    );
  }),

  [UNAUTHORIZE]: async ({ commit, dispatch }) => {
    await dispatch(AUTH_CLEAN);
    await dispatch('entities/deleteAll', null, { root: true });
    commit(M_UNAUTHORIZE);
    Vue.$log.debug('UNAUTHORIZE done');
    setUser(null);

    if (router.currentRoute.path !== '/') {
      router.push({ path: '/' });
    }
  },

  async [POST_AUTHORIZATION]({ dispatch }) {
    try {
      await dispatch(USER_REQUEST, null, { root: true });
      await dispatch(USER_LEAGUE_REQUEST, null, { root: true });
      dispatch(SESSIONS_FILTER_CLEAN, null, { root: true });

      return true;
    } catch (error) {
      captureException(error);
      Vue.$log.debug(error);
      return false;
    }
  },

  CHECK_AUTHENTICATION: async ({ state, commit, rootGetters, dispatch }, payload) => {
    if (state.initialized) {
      Vue.$log.debug('Checking auth from inside');
      return rootGetters.isAuthorized;
    }

    try {
      Vue.$log.debug('Checking auth from cookie');
      const authenticatedByCookie = await dispatch(IS_AUTHENTICATED);
      commit('initialize');
      return rootGetters.isAuthorized;
    } catch (error) {
      captureException(error);
      Vue.$log.debug(error);
      commit('initialize');
      return false;
    }
  },
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = STATES.LOADING;
  },
  [AUTH_SUCCESS]: (state) => {
    state.status = STATES.AUTHORIZED;
  },
  [AUTH_ERROR]: (state) => {
    state.status = STATES.ERROR;
  },
  [M_UNAUTHORIZE]: (state) => {
    state.status = STATES.UNAUTHORIZED;
  },

  initialize(state) {
    state.initialized = true;
  },
};

export default {
  state() {
    return {
      status: '',
      initialized: false,
    };
  },
  getters: GETTERS,
  actions,
  mutations,
};
