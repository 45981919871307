import Vue from 'vue';
import groupBy from 'lodash/groupBy';
import { Client, DateTime } from '@/services';
import { Media, SessionV1, Session, Result, FormattedResult } from '@/models/orm/Hierarchy';
import { Services } from '@/store/actions/api';
import STATES from '@/store/actions/states';
import generateTeam from '@/utils/TeamGenerator';
import sessionResultsFormatter from '@/formatters/sessionResultsFormatter';
import PlayerRepository from '@/repositories/PlayerRepository';
import { jsonToFormData } from '@/utils/http-common';
import RankingStatsMapper from '../mappers/rankingStatMapper';
import { captureException } from '../plugins/sentry';

const RequestMap = new Map();

const SessionModel = Session;

export default {
  getSingle(id, old) {
    if (old) {
      return SessionV1.find(id);
    }

    let query = Session.query().where('id', id);

    return query.with([
      'lineups.battingOrder.player',
      'lineups.substitutions',
      'lineups.switches',
      'lineups.bench.player',
      'lineups.team.season',
      'lineups.team.players.team',
      'mediaForResult',
      'mediaForSession',
      'results',
      'resultsv1',
    ]).first();
  },

  async fetchSession(id, oldModel) {
    try {
      let params;
      if (oldModel) {
        params = {
          service: Services.BaseballSession.key,
          method: Services.BaseballSession.methods.GetSession.key,
          ...Services.BaseballSession.methods.GetSession.query,
        };
      } else {
        params = {
          service: Services.BaseballSessionManagement.key,
          method: Services.BaseballSessionManagement.methods.GetSession.key,
          ...Services.BaseballSessionManagement.methods.GetSession.query,
        };
      }

      params.SessionID = id;

      const { data: fromApi } = await Client({ params });

      const TTL_VALUE = process.env.VUE_APP_TTL_SESSION_VALUE;
      const TTL_UNIT = process.env.VUE_APP_TTL_SESSION_UNIT;

      fromApi.ttlExpire = DateTime.toUnixTs(DateTime.future(TTL_VALUE, TTL_UNIT));

      let injected;

      if (oldModel && fromApi?.SessionID) {
        injected = await SessionV1.insertOrUpdate({ data: [fromApi] });
      } else if (fromApi?.id) {
        /** @var {Result[]} results */
        const { results } = fromApi;

        /** @type {FormattedResult[]} */
        const formatted = sessionResultsFormatter(results);

        fromApi.resultsv1 = formatted;

        injected = await Session.insertOrUpdate({ data: [fromApi],
          insertOrUpdate: ['media', 'results', 'formattedResults', 'lineupTags'] });
      }

      const fromOrm = this.getSingle(id);

      return { session: fromOrm };
    } catch (error) {
      Vue.$log.debug(error);
      Vue.$log.debug('Error when retrieving session ', id);
      captureException(error);
      return { error: `Error when retrieving session ${id}` };
    }
  },

  saveRequest(id) {
    // save request and return
    RequestMap.set(id, this.fetchSession(id));

    return RequestMap.get(id);
  },

  async get(id, { ttl, force } = { ttl: true, force: false}) {
    if (!id) {
      throw TypeError('Session ID needs to be an positive integer.');
    }
    const session = this.getSingle(id);

    // use cached value or existing request
    if (!force) {
      // get from ORM
      if (session) {
        if (RequestMap.has(id)) {
          RequestMap.delete(id);
        }
        const nowTS =  DateTime.toUnixTs();
        if (ttl && session.ttlExpire < nowTS) {
          return this.saveRequest(id);
        }
        return { session };
      }

      if (RequestMap.has(id)) {
        // get existing request
        return RequestMap.get(id);
      }
    }

    return this.saveRequest(id);
  },

  async deleteResults(resultsToDelete) {
    // TODO: check and implement this properly !!!
    if (!Array.isArray(resultsToDelete) || resultsToDelete.length === 0) {
      throw Error('Invalid format of resultsToDelete');
    }

    const params = {
      method: Services.BaseballSession.methods.DeleteResults.key,
      service: Services.BaseballSession.key,
      ResultsJSON: JSON.stringify(resultsToDelete),
    };

    try {
      const resp = await Client({ params });

      if (!resp || !resp.data) {
        return false;
      }
      // TODO discuss whatever remove only selected results in SessionData Vs reload whole session by request
      // sample response, ID for successfully deleted result, in case of issue there will be an error prop with details
      /* [
          {
              "ID": 1
          },
          {
              "ID": 10,
              "error": {
                  "Code": "RECORD_DOES_NOT_EXIST_ANYMORE",
                  "Message": "ID:10"
              }
          },
          {
              "ID": 12348
          },
          {
              "ID": 12349
          }
      ] */
      if (!Array.isArray(resp.data)) {
        Vue.$log.error('Invalid response data', resp.data);
        return { error: 'Invalid response data' };
      }
      // TODO - for results that cannot be deleted due to error - feedback to user within data table?
      const deletedResults = resp.data
        .filter(record => !record?.error)
        .map(record => parseInt(record.ID));

      Result.delete((result) => {
        return deletedResults.length && deletedResults.includes(result.id);
      });

      FormattedResult.delete((result) => {
        return deletedResults.length && deletedResults.includes(result.ResultID);
      });

      return { message: true }
    } catch (error) {
      Vue.$log.error('Parsing response data', error);
      captureException(error);
      return { error: 'Parsing response data' };
    }
  },

  mapPlayersToOptions(session, players) {
    if (!session || !session.id) {
      return [];
    }

    const mapped = players.map(p => {
      if(p?.type === 'unknown') {
        return PlayerRepository.getDefault(p.id);
        throw Error('TODO: Implement this !!!');
      }
      return p;
    });

    const grouped = groupBy(mapped, 'teamID');

    let options = [];

    if (session.homeLineup && grouped[session.homeLineup.teamID] && session.homeLineup.team) {
       const playerIDs = grouped[session.homeLineup.teamID].map(p => p.id);
       const homeTeamHeader = [{ header: `${session.homeLineup.team.displayName}`, group: playerIDs }];
       options = [
         ...homeTeamHeader,
         ...grouped[session.homeLineup.teamID].map(p => ({
            text: p.FormattedName,
            value: p.id,
         }))
       ];
    }

    if (session.awayLineup && grouped[session.awayLineup.teamID] && session.awayLineup.team) {
       const playerIDs = grouped[session.awayLineup.teamID].map(p => p.id);
       const awayTeamHeader = [{ header: `${session.awayLineup.team.displayName}`, group: playerIDs }];
       options = [
         ...options,
         ...awayTeamHeader,
         ...grouped[session.awayLineup.teamID].map(p => ({
           text: p.FormattedName,
           value: p.id,
          }))
        ];
      }

      if (grouped.unknown) {
      const playerIDs = grouped.unknown.map(p => p.id);
       const withoutTeamHeader = [{ header: `Unknown team`, group: playerIDs }];
       options = [
         ...options,
         ...withoutTeamHeader,
         ...grouped.unknown.map(p => ({
            text: p.FormattedName,
            value: p.id,
         }))
       ];
    }

    return options;
  },

  async fetchStats(id) {
    try {
      let params = {
        service: Services.BaseballSessionManagement.key,
        method: Services.BaseballSessionManagement.methods.GetStatsForScope.key,
        // ...Services.BaseballSessionManagement.methods.GetStatsForScope.query,
      };

      params.SessionID = id;

      const { data: fromApi } = await Client({ params });

      // map the data to correspond to Entities.RankingStat class
      const mapper = new RankingStatsMapper();

      if (fromApi instanceof Array) {
        return fromApi.map(p => {
          p.parameters = mapper.mapArray(p.parameters || []);
          return p;
        });
      }

      return fromApi;
    } catch(err) {
      Vue.$log.debug(err);
      captureException(err);
      return [];
    }
  },

  async deleteMediaForResult(mediaId) {
    if (!mediaId) {
      throw TypeError('Media ID needs to be an positive integer.');
    }

    try {
      await Media.find(mediaId).$delete();
      return { message: `Media with id ${mediaId} was deleted` };
    } catch (error) {
      Vue.$log.debug(error);
      Vue.$log.debug('Error when deleting media with id ', mediaId);
      return { error: `Error when deleting media with id ${mediaId}` };
    }
  },

  /**
   * @param {Number} sessionId
   * @param {boolean} value
   */
  async setVerifiedFlag(sessionId, value) {
    if (typeof sessionId !== 'number' || typeof value !== 'boolean') {
      throw TypeError('Provided params have incorrect type');
    }
    if (sessionId <= 0) {
      throw RangeError('sessionId must be positive integer');
    }

    try {
      const params = {
        service: Services.BaseballSessionManagement.key,
        method: Services.BaseballSessionManagement.methods.SetSessionDataVerified.key,
      };

      let payload = Services.BaseballSessionManagement.methods.SetSessionDataVerified.params;

      payload.IsDataVerified = value;
      payload.SessionID = sessionId;

      const data = jsonToFormData(payload);

      const { data: fromApi } = await Client({
        params,
        method: 'post',
        data,
      });

      const updated = await Session.update({id: sessionId, dataVerified: value});

      return true;
    } catch (err) {
      Vue.$log.debug(err);
      return false
    }
  }
};
