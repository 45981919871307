import { Model } from '@vuex-orm/core';

export class PlayerSubstitution extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'playerSubstitutions';

  static primaryKey = ['enteredTagID', 'replacedTagID', 'enteredDate'];

  static state() {
    return {
      fetching: false,
    };
  }

  static fields() {
    return {
      lineup_id: this.number(null).nullable(),
      enteredTagID: this.number(null),
      replacedTagID: this.number(null),
      type: this.string(''),
      enteredDate: this.string(''),
    };
  }

  get date() {
    return this.enteredDate;
  }
}

export default PlayerSubstitution;
