import isBoolean from 'lodash/isBoolean';

export const FAKE_API_REQUEST = 'FAKE_API_REQUEST';
export const HANDLE_API_ERROR = 'HANDLE_API_ERROR';
// propsed convention is M_ stands for mutation shortcut to indicate that this const represents mutation and commit instead dispatch should be called
export const M_TOGGLE_API_ALERT = 'M_TOGGLE_API_ALERT';
export const M_RESET_API_ALERT = 'M_RESET_API_ALERT';
export const M_SET_API_ALERT = 'M_SET_API_ALERT';

const required = (val) => typeof val !== 'undefined';
const numericPositive = (val) => val > 0;

export const Services = Object.freeze({
  Authentication: {
    key: 'AuthenticationService',
    methods: {
      IsUserLoggedIn: {
        key: 'IsUserLoggedIn',
        type: 'get',
      },
      LogOutUser: {
        key: 'LogOutUser',
        type: 'post',
      },
      TryAuthenticate: {
        key: 'TryAuthenticate',
        type: 'post',
      },
    },
  },

  FlightScopeUser: {
    key: 'FlightScopeUserService',
    methods: {
      InsertUser: {
        key: 'InsertUser',
        type: 'post',
        params: {
          Email: String,
          DisplayName: String,
          Password: String,
          GenderID: Number,
          Strict: Number,
        },
      },
      UserVerification: {
        key: 'UserVerification',
        // TODO: not used yet.
        params: {
          Email: String,
          Key: String,
          IsAccepted: Number,
        },
      },
      RequestPasswordReset: {
        key: 'RequestPasswordReset',
        // TODO: not used yet.
        params: {
          Email: String,
          Token: String,
          Password: String,
        },
      },
      ConfigureUser: {
        key: 'ConfigureUser',
        type: 'post',
      },
      EmailExist: {
        key: 'EmailExist',
      },
      ResetPassword: {
        key: 'ResetPassword',
      },
      DeleteConnectedTag: {
        key: 'DeleteConnectedTag',
        params: {
          TagID: Number,
        },
        method: 'post'
      },
      ChangePassword: {
        key: 'ChangePassword',
        params: {
          Password: String,
          NewPassword: String,
          InvalidateToken: Boolean,
        },
      },

      ConfigureAvatar: {
        key: 'ConfigureAvatar',
        params: {
          // Type: String,
          AvatarBase64: File,
        },
      },
    },
  },

  Subscription: {
    key: 'SubscriptionService',
    methods: {
      NewTransaction: {
        key: 'NewTransaction',
        type: 'post',
        query: {
          gateway: String, // stripe
        },
        params: {
          userid: Number,
          recurringplan: String,
          email: String,
          payment_method_id: [Number, String],
        },
      },
      ListServicePlans: {
        key: 'ListServicePlans',
        type: 'get',
        gateways: {
          stripe: 'stripe',
        },
      },
      GetTransaction: {
        key: 'GetTransaction',
        type: 'post',
        query: {
          gateway: String,
        },
      },
      CancelMembership: {
        key: 'CancelMembership',
        type: 'post',
      },
      UpdateMembership: {
        key: 'UpdateMembership',
        type: 'post',
        query: {
          gateway: String,
        },
      },
    },
  },

  TeamManagement: {
    key: 'BaseballTeamManagementService',
    methods: {
      FindPlayers: {
        key: 'FindPlayers',
        type: 'get',
        authorized: true,
        query: {
          NameOrId: [String, Number],
          LeagueID: [Number],
        }
      },

      ListLeague: {
        key: 'ListLeagueOrganizationAndSeason',
        type: 'get',
        authorized: true,
      },

      GetBaseballRosterPlayers: {
        key: 'GetBaseballRosterPlayers',
        type: 'get',
        query: {
          TeamID: Number,
          ActiveOnly: Boolean,
          IncludeDeleted: Boolean,
        }
      },

      GetTeams: {
        key: 'GetTeams',
      },

      GetPublicTeams: {
        key: 'GetPublicTeams',
      },

      GetTeam: {
        key: 'GetTeam',
        query: {
          TeamID: Number,
        },
      },

      GetTeamRosters: {
        key: 'GetTeamRosters',
      },

      SendFollowIntent: {
        key: 'SendFollowIntent',
        params: {
          TeamID: Number,
          TagID: Number,
        }
      },

      ListConnectedTags: {
        key: 'ListConnectedTags',
        query: {
          StartIndex: Number,
          Count: Number,
        },
      },

      GetPlayerTag: {
        key: 'GetPlayerTag',
        query: {
          TagID: Number,
        },
      },
    },
  },

  BaseballPlayer: {
    key: 'BaseballPlayerService',
    methods: {
      GetTagDetails: {
        key: 'GetTagDetails',
      },
      GetPlayerTags: {
        key: 'GetPlayerTags',
      },
      GetPlayerTagVideos: {
        key: 'GetPlayerTagVideos',
      },
      GetTeamRoster: {
        key: 'GetTeamRoster',
        type: 'get',
        query: {
          TeamID: Number,
        },
      },
      GetProfile: {
        key: 'GetProfile',
      },
    },
  },

  BaseballSessionManagement: {
    key: 'BaseballSessionManagementService',
    methods: {
      SetSessionDataVerified: {
        key: 'SetSessionDataVerified',
        type: 'post',
        params: {
          SessionID: {
            type: Number,
            required: true,
            rules: [required, numericPositive],
          },
          IsDataVerified: {
            type: Boolean,
            required: true,
            rules: [required, isBoolean],
          },
        },
      },
      GetManagedLeagueSessionList: {
        key: 'GetManagedLeagueSessionList',
      },

      GetSessionList: {
        key: 'GetSessionList',
      },

      GetSession: {
        key: 'GetSession',
        type: 'get',
        query: {
          SessionID: {
            type: Number,
            required: true,
            rules: [required, numericPositive],
          }
        },
      },

      GetStatsForScope: {
        key: 'GetStatsForScope',
        type: 'get',
        query: {
          SessionID: {
            type: Number,
            required: true,
            rules: [required, numericPositive],
          },
          TagID: {
            type: Number,
            rules: [numericPositive],
          },
          OpponentID: {
            type: Number,
            rules: [numericPositive],
          },
          TeamID: {
            type: Number,
            rules: [numericPositive],
          },
          OpponentTeamID: {
            type: Number,
            rules: [numericPositive],
          },
          DateFrom: {
            type: String,
          },
          DateTo: {
            type: String,
          },
          PitchType: {
            type: String,
          },
        }
      },

      GetCumulativeStatsForScope: {
        key: 'GetCumulativeStatsForScope',
        type: 'get',
        query: {
          TagID: {
            type: Number,
            required: true,
            rules: [required, numericPositive],
          },
          GroupBy: {
            type: String,
            required: true,
          },
          SessionType: {
            type: String,
            required: true,
          },
          Context: {
            type: String,
            required: true,
          },
        }
      },

      DeleteMedia: {
        key: 'DeleteMedia',
        type: 'post',
        params: {
          MediaID: Number
        },
      },
    },
  },

  BaseballReport: {
    key: 'BaseballReportService',
    methods: {
      /**
       * @deprecated
       */
      GetTagDataReport: {
        key: 'GetTagDataReport',
      },
      GetTagReport: {
        key: 'GetTagReport',
      },
      GetEventTeams: {
        key: 'GetEventTeams',
      },
    },
  },

  BaseballSession: {
    key: 'BaseballSessionService',
    methods: {
      GetSession: {
        key: 'GetSession',
        type: 'get',
        query: {
          SessionID: Number,
        },
      },
      DeleteResults: {
        key: 'DeleteResults',
      },
    },
  },

  FlightScopeEDH: {
    key: 'FlightScopeEDHService',
    methods: {
      SendSalesInquiry: {
        key: 'SendSalesInquiry',
        type: 'post'
      },
    },
  },

  Twitter: {
    key: 'TwitterService',
    methods: {
      IsAuthorized: { key: 'IsAuthorized' },
      RequestOauth: {
        key: 'RequestOauth',
        type: 'post',
        params: {
          AutoRedirect: Boolean,
          RedirectUrl: String,
        },
      },
      MediaUpload: {
        key: 'MediaUpload',
        type: 'post',
        params: {
          MediaID: Number,
        },
      },
      StatusUpdate: {
        key: 'StatusUpdate',
        type: 'post',
        params: {
          status: {
            type: String,
            required: true,
          },
          // in_reply_to_status_id: {
          //   type: String,
          //   required: true,
          // },
          // auto_populate_reply_metadata: {
          //   type: Boolean,
          // },
          // exclude_reply_user_ids: {
          //   type: String,
          //   required: true,
          // },
          // attachment_url: {
          //   type: String,
          // },
          media_ids: {
            type: String,
            required: true,
          },
          // possibly_sensitive: {
          //   type: Boolean,
          // },
          // lat: {
          //   type: Number,
          // },
          // long: {
          //   type: Number,
          // },
          // place_id: {
          //   type: String,
          //   required: true,
          // },
          // display_coordinates: {
          //   type: Boolean,
          // },
          // trim_user: {
          //   type: Boolean,
          // },
          // enable_dmcommands: {
          //   type: Boolean,
          // },
          // fail_dmcommands: {
          //   type: Boolean,
          // },
          // card_uri: {
          //   type: String,
          //   required: true,
          // },
        },
      },
    },
  },

  FlightScopeMedia: {
    key: 'FlightScopeMediaService',
    methods: {
      DeleteMedia: {
        key: 'DeleteMedia',
        type: 'post',
        params: {
          MediaID: Number
        },
      },
      IsMediaUploadAllowed: {
        key: 'IsMediaUploadAllowed',
      },
    }
  }
});

export const SessionType = {
  GAMES: 'Game',
  PITCHING: 'PitchingSession',
  HITTING: 'BattingSession',
  PRACTICE: 'GamePractice',
  LIVEBP: 'LiveBP',
};

// '1', 'PitchingSession'
// '2', 'BattingSession'
// '3', 'PoptimeSession'
// '4', 'MevoPitching'
// '5', 'MevoHitting'
// '6', 'Game'
// '7', 'GamePractice'
