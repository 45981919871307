import { actions, mutations } from '@/store/helpers';

export default {
  state() {
    return {
      fetching: false,
      fetched: false,
    };
  },

  actions: {
    setFetching: actions.setFetching,
    setFetched: actions.setFetched,
  },

  mutations: {
    setFetching: mutations.setFetching,
    setFetched: mutations.setFetched,
  },
};
