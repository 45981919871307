import { unitsConverter } from '../plugins/converter';

/**
 *
 * @param {*} baseValue
 * @param {*} type
 * @param {*} targetSystem
 * @param {{ noValueFallback?: any, fractionalDigits?: number, fractionalPadding?: boolean, decimalSeparator?: string, thousandSeparator?: string, symbolPrefix?: string, symbolSuffix?: string, unitSymbolIndex?: number }} config
 * @returns
 */
const convertUnit = function convertUnit(
  baseValue, type, targetSystem,
  {
    noValueFallback,
    fractionalDigits,
    decimalSeparator,
    thousandSeparator,
    unitSymbolIndex,
    symbolPrefix,
    symbolSuffix,
    fractionalPadding
  } = { noValueFallback: '-', fractionalDigits: 2, fractionalPadding: true },
)
{
  let conversionResult = unitsConverter.convertType(baseValue, type, targetSystem);
  if (typeof conversionResult !== 'object') {
    return noValueFallback;
  }

  if (typeof fractionalPadding === 'undefined') {
    fractionalPadding = true;
  }

  return conversionResult.formatWithSymbol(
    noValueFallback, fractionalDigits, thousandSeparator, decimalSeparator, symbolPrefix, symbolSuffix, unitSymbolIndex, fractionalPadding
  );
};

/**
 *
 * @param {*} baseValue
 * @param {*} type
 * @param {*} targetSystem
 * @param {{ noValueFallback?: any, fractionalDigits?: number, fractionalPadding?: boolean, decimalSeparator?: string, thousandSeparator?: string }} config
 * @returns
 */
const convertUnitNoSymbol = function convertUnitNoSymbol(
  baseValue, type, targetSystem,
  {
    noValueFallback,
    fractionalDigits,
    decimalSeparator,
    thousandSeparator,
    fractionalPadding
  } = { noValueFallback: '-', fractionalDigits: 2, fractionalPadding: true },
) {
  if (type === 'STRING') {
    return baseValue || noValueFallback;
  }

  let conversionResult = unitsConverter.convertType(baseValue, type, targetSystem);

  if (typeof conversionResult !== 'object') {
    return noValueFallback;
  }

  if (typeof fractionalPadding === 'undefined') {
    fractionalPadding = true;
  }

  return conversionResult.format(
    noValueFallback, fractionalDigits, thousandSeparator, decimalSeparator, fractionalPadding
  );
};

/**
 *
 * @param {String} type
 * @param {String} targetSystem
 * @param {Number} unitSymbolIndex
 * @returns {String}
 */
const unitSymbolProvider = function unitSymbolProvider(
  type, targetSystem, unitSymbolIndex = 0,
) {
  let unitDefinition = unitsConverter.getUnitForType(type, targetSystem);
  if (!unitDefinition) {
    return '';
  }

  return unitDefinition.getSymbol(unitSymbolIndex || 0) || '';
};

export { convertUnit, unitSymbolProvider, convertUnitNoSymbol };
