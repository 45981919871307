import { Model } from '@vuex-orm/core';

export class PositionSwitch extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'positionSwitches';

  static primaryKey = ['fielderATagID', 'fielderBTagID', 'switchDate'];

  static state() {
    return {
      fetching: false,
    };
  }

  static fields() {
    return {
      lineup_id: this.number(null).nullable(),
      fielderATagID: this.number(null),
      fielderBTagID: this.number(null),
      switchDate: this.string(''),
    };
  }

  get date() {
    return this.switchDate;
  }
}

export default PositionSwitch;
