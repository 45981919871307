import Vue from 'vue';
import { Client } from '@/services';
import { Roster } from '@/models/orm/Hierarchy';
import { Services } from '@/store/actions/api';
import STATES from '@/store/actions/states';
import {
  wsRosterPlayerToSessionPlayer,
  defaultSessionPlayerWithID,
} from '@/utils/mappers';
import { captureException } from '@sentry/vue';

const RequestMap = new Map();

export default {
  getSingle(id) {
    return Roster.find(id);
  },

  async fetchRoster(id) {
    try {
      const params = {
        method: Services.BaseballPlayer.methods.GetTeamRoster.key,
        service: Services.BaseballPlayer.key,
        ...Services.BaseballPlayer.methods.GetTeamRoster.query,
      };

      params.TeamID = id;

      const { data: fromApi } = await Client({ params });

      if (fromApi?.length) {
        let injected = await Roster.insertOrUpdate({ data: [{ team_id: id, tags: fromApi }] });
      }

      const roster = this.getSingle(id);

      return roster.tags;
    } catch (error) {
      captureException(error);
      Vue.$log.debug(error);
      Vue.$log.debug('Error when retrieving roster for team ', id);
      return [];
    }
  },

  async get(id, force) {
    if (!id) {
      throw TypeError('Team ID needs to be an positive integer.');
    }
    const roster = this.getSingle(id);

    // use cached value or existing request
    if (!force) {
      // get from ORM
      if (roster) {
        if (RequestMap.has(id)) {
          RequestMap.delete(id);
        }
        return roster.tags;
      }

      if (RequestMap.has(id)) {
        // get existing request
        return RequestMap.get(id);
      }
    }

    // save request and return
    RequestMap.set(id, this.fetchRoster(id));

    return RequestMap.get(id);
  },

  /**
   * @return {null | SessionPlayer}
   */
  playerOrTag({ teamId, teams, playerId, tagId, placeholderFallback = false }) {
    if (!playerId && !tagId) {
      throw new Error('Please provide id of player or tag');
    }

    let playerData = { ...defaultSessionPlayerWithID(tagId ?? playerId) };
    let playerFound = false;
    let combined = [];

    if (teamId && teamId != 0) {
      let roster = Roster.find(teamId);
      if (roster && roster.tags?.length) {
        // Vue.$log.debug('getting player data from specific roster', tagId, teamId);
        combined = roster.tags;
      }
    } else if (teams && teams.length) {
      // Vue.$log.debug('getting player data from couple of rosters', tagId, teams);
      teams.forEach((team) => {
        let roster = Roster.find(team);
        if (roster && roster.tags?.length) {
          combined = [...combined, ...roster.tags];
        }
      });
    } else {
      Vue.$log.error('getting player data from all rosters is not implemented !!!');
    }

    if (combined.length) {
      if (typeof playerId === 'number' && playerId > 0) {
        // TODO: - proper player mapping
      } else if (typeof tagId === 'number' && tagId > 0) {
        // Vue.$log.debug(`Searching for ${tagId} in `, combined);
        const tagData = combined.find((tagOrPlayer) => tagOrPlayer.TagID == tagId);
        // Vue.$log.debug('tagData', tagData);

        if (tagData) {
          playerFound = true;
          //tagData.TeamId = teamId;
          playerData = wsRosterPlayerToSessionPlayer(tagData, playerData);
        }
      }
    }

    if (playerData.Type === 'placeholder') {
      playerData.UId = `placeholder-${playerId}-${tagId}`;
    } else {
      playerData.UId = `${playerData.Type}-${playerData.Id}`;
    }

    return !placeholderFallback && !playerFound ? null : playerData;
  },
};
