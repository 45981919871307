import Headphones from '@/assets/icons/headphones.svg';
import IconCalendar from '../assets/icons/calendar.svg';
import IconLogout from '../assets/icons/exit_white.svg';
import Switch from '../assets/icons/Switch_black.svg';
import List from '../assets/icons/list2.svg';
import PitchLocationChart from '../assets/icons/pitch_location_chart.svg';
import PitchMovementChart from '../assets/icons/pitch_movement_chart.svg';
import PitcherPerformacnceTracking from '../assets/icons/pitcher_performacnce_tracking.svg';
import ReleaseExtensionChart from '../assets/icons/release_extension_chart.svg';
import ReleaseExtensionChartSoftBall from '../assets/icons/release_extension_chart_softball.svg';
import ReleasePointChart from '../assets/icons/release_point_chart.svg';
import SettingsIcon from '../assets/icons/settings.svg';
import ExportIcon from '../assets/icons/export_white.svg';
// TODO replace BatterPerformanceTracking, BatterSpray with proper svg files
import BatterPerformanceTracking from '../assets/icons/batter_performance_tracking.svg';
import ContactPointChart from '../assets/icons/contact_point_chart.svg';
import BatterSpray from '../assets/icons/batter_spray.svg';
import Search from '../assets/icons/search.svg';
import ArrowRight from '../assets/icons/arrow_right.svg';
import CloseThin from '../assets/icons/close_thin.svg';
import Trash from '../assets/icons/trash-svgomg.svg';
import Camera from '../assets/icons/camera-svgomg.svg';
import Info from '../assets/icons/info-svgomg.svg';
import DataVerified from '../assets/icons/data_verified.svg';
import DataVerifiedWhite from '../assets/icons/data_verified_white.svg';

export default {
  iconfont: 'mdi',
  values: {
    calendar: {
      component: IconCalendar,
    },
    logout: {
      component: IconLogout,
    },
    switch: {
      component: Switch,
    },
    list: {
      component: List,
    },
    chartReleasePoint: {
      component: ReleasePointChart,
    },
    chartPitchLocation: {
      component: PitchLocationChart,
    },
    chartReleaseExtension: {
      component: ReleaseExtensionChart,
    },
    chartReleaseExtensionSoftball: {
      component: ReleaseExtensionChartSoftBall,
    },
    chartPitchMovement: {
      component: PitchMovementChart,
    },
    chartPitcherPerformacnce: {
      component: PitcherPerformacnceTracking,
    },
    settings: {
      component: SettingsIcon,
    },
    export: {
      component: ExportIcon,
    },
    chartBatterSpray: {
      component: BatterSpray,
    },
    chartBatterPerformance: {
      component: BatterPerformanceTracking,
    },
    chartContactPoint: {
      component: ContactPointChart
    },
    search: {
      component: Search,
    },
    throwing: {
      component: () => import(/* webpackChunkName "icons" */ '@/assets/icons/baseball_ball.svg'),
    },
    batting: {
      component: () => import(/* webpackChunkName "icons" */ '@/assets/icons/baseball_bat.svg'),
    },
    arrowRight: {
      component: ArrowRight,
    },
    closeThin: {
      component: CloseThin,
    },
    share: {
      component: () => import(/* webpackChunkName "icons" */ '@/assets/icons/share.svg'),
    },
    play: {
      component: () => import(/* webpackChunkName "icons" */ '@/assets/icons/play.svg'),
    },
    calendar_thin: {
      component: () => import(/* webpackChunkName "icons" */ '@/assets/icons/calendar_thin.svg'),
    },
    lock: {
      component: () => import(/* webpackChunkName "icons" */ '@/assets/icons/lock.svg'),
    },
    headphones: {
      component: Headphones,
    },
    trash: {
      component: Trash,
    },
    camera: {
      component: Camera,
    },
    infoCustom: {
      component: Info,
    },
    verified: {
      component: DataVerified,
    },
    verifiedWhite: {
      component: DataVerifiedWhite,
    },
  },
};
