// import Vue from 'vue';
import findIndex from 'lodash/findIndex';
import {
  SET_ALERT, REMOVE_ALERT, M_SET_ALERT, M_RESET_ALERTS,
} from '../actions/alerts';

const actions = {
  [SET_ALERT]: ({ state, commit }, newAlert) => {
    // Todo: maybe check if the id does not collid
    newAlert.id = state.index;
    commit(M_SET_ALERT, newAlert);
  },
  [REMOVE_ALERT]: ({ state, commit }, id) => {
    const index = findIndex(state.list, { id });
    if (index !== -1) {
      commit(REMOVE_ALERT, index);
    }
  },

  RESET_ALERTS({ commit }) {
    commit(M_RESET_ALERTS);
  },
};

const mutations = {
  [M_SET_ALERT](state, newAlert) {
    state.list.push({ ...newAlert });
    state.index++;
  },

  [M_RESET_ALERTS](state) {
    state.list = [];
    state.index = 1;
  },

  [REMOVE_ALERT](state, index) {
    state.list.splice(index, 1);
  },
};

export default {
  state() {
    return {
      list: [],
      index: 1,
    };
  },
  actions,
  mutations,
};
