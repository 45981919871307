import Vue from 'vue';
import Store from '../../../store';
import Router from '../../../router';
import { database } from '../../../store/orm';

export default {
  namespaced: true,
  state() {
    return {
      initialized: false,
    };
  },

  mutations: {
    initialize(state) {
      state.initialized = true;
      Vue.$log.info('System initialized.');
    },
  },

  actions: {
    async bootstrapModules({ dispatch }) {
      const modulesToLoad = process.env.VUE_APP_FEATURES.split(',');

      for (let i = 0; i < modulesToLoad.length; i++) {
        let moduleName = modulesToLoad[i];
        let { default: module } = await import(/* webpackChunkName: "module/[request]" */ `../../${moduleName}/module`);
        await dispatch('initializeModule', module);
      }

      // Needs to be added as the last route
      Router.addRoute(
        {
          path: '*',
          redirect: {
            path: Store.getters.isAuthorized ? process.env.VUE_APP_DEFAULT_REDIRECT : '/',
          },
        },
      );
    },

    async initialize({ dispatch, commit }) {
      Vue.$log.info('System initializing...');

      await dispatch('bootstrapModules');

      commit('initialize');
    },

    async initializeModule({ dispatch }, module) {

      let $db = database;

      module.routes.forEach((route) => {
        Router.addRoute(route);
      });

      if (module?.orm && !$db.entities.some(ent => ent.name === module.orm.entity)) {
        database.register(module.orm, module.store);
      } else if (!Store.hasModule(module.name)) {
        Store.registerModule(module.name, module.store);
        await dispatch(`${module.name}/initialize`, null, { root: true });
      }
    },
  },
};
