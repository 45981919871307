function generateTeam(id) {
  return {
    TeamID: id,
    TeamDisplayName: 'Unknown team',
    Photo: null,
    Logo: '/img/avatar/default_team.jpg',
    IsPublic: false,
    TS: new Date().getFullYear(),
    CreateDate: new Date().getFullYear(),
    Location: '',
    Latitude: null,
    Longitude: null,
    ExternalTeamType: 'FlightScope',
    ExternalTeamID: `FS-unknown-${id}`,
    ShortCode: 'UT',
  };
}

export default generateTeam;
