const STRIPE_CREATE_OPTIONS = Object.freeze({
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT,
  apiVersion: process.env.VUE_APP_API_VERSION,
  locale: process.env.VUE_APP_LOCALE,
  // See https://stripe.com/docs/js/elements_object/create#stripe_elements-options
  elementsOptions: {
    fonts: [],
    locale: 'auto',
  },
});

export { STRIPE_CREATE_OPTIONS };
