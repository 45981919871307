/* eslint-disable no-console */

import { register } from 'register-service-worker';
import { IS_PRODUCTION } from './constants';

if (IS_PRODUCTION) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'SW: App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered(registration) {
      console.log('SW: Service worker has been registered.');
      setInterval(() => {
        console.log('SW: Forced update in internal of 1 hour');
        registration.update();
      }, 1000 * 60 * 60); // e.g. hourly checks
    },
    cached() {
      console.log('SW: Content has been cached for offline use.');
    },
    updatefound() {
      console.log('SW: New content is downloading.');
    },
    updated(registration) {
      console.log('SW: New content is available; please refresh. Dispatching swUpdated event.');
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration }),
      );
    },
    offline() {
      console.log('SW: No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('SW: Error during service worker registration:', error);
    },
  });
}
