import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  key: process.env.VUE_APP_SPORT_TYPE.toLowerCase(),
  storage: window.localStorage,
});

const vuexCookie = new VuexPersistence({
  key: process.env.VUE_APP_SPORT_TYPE.toLowerCase(),
  storage: window.cookieStorage,
  reducer: (state) => ({
    app: {
      filters: state.app.filters,
    },
  }),
});

export { vuexLocal, vuexCookie };
