const getTeamShortCode = displayName => {
  let shortCode = '';
  let shortCodeParts;

  if (typeof displayName === 'string') {
    shortCodeParts = displayName.split(' ');
  }

  if (shortCodeParts?.length === 1) {
    shortCode = displayName.substr(0, 3);
  }
  else if (shortCodeParts?.length > 1) {
    for (let i = 0; i <= 2; i++) {
      if (shortCodeParts[i]) {
        shortCode += shortCodeParts[i].substr(0, 1);
      }
    }
  }

  shortCode = shortCode.toUpperCase();

  return shortCode;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getTeamShortCode
};
