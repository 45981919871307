import Vue from 'vue';
import { AbilityBuilder, Ability, subject } from '@casl/ability';
import { User } from '@/models/orm/Hierarchy';

export const AclEntities = Object.freeze({
  All: 'all',

  BasicCareerStats: 'BasicCareerStats',
  AdvancedCareerStats: 'AdvancedCareerStats',

  BasicPlayerReport: 'BasicPlayerReport',
  AdvancedPlayerReport: 'AdvancedPlayerReport',

  CareerStats: 'CareerStats',
  ConnectedTag: 'ConnectedTag',
  Csv: 'Csv',
  Game: 'Game',
  League: 'League',
  Player: 'Player',
  PlayerReport: 'PlayerReport',
  PublicTeams: 'PublicTeams',
  PublicView: 'PublicView',
  Session: 'Session',
  Subscription: 'Subscription',
  User: 'User',
  Video: 'Video',
  VideoClipper: 'VideoClipper',
  EnhancedLeaderboard: 'EnhancedLeaderboard',
});

export const AclPermissions = Object.freeze({
  add: 'add',
  delete: 'delete',
  edit: 'edit',
  export: 'export',
  list: 'list',
  manage: 'manage',
  read: 'read',
  share: 'share',
  update: 'update',
});

const video = subject.bind(null, AclEntities.Video);
const league = subject.bind(null, AclEntities.League);
const subscription = subject.bind(null, AclEntities.Subscription);
const session = subject.bind(null, AclEntities.Session);
const game = subject.bind(null, AclEntities.Game);
const playerReport = subject.bind(null, AclEntities.PlayerReport);

const PUBLIC_ABILITIES = Object.freeze([{ actions: AclPermissions.read, subject: AclEntities.PublicView }]);

const ability = new Ability(PUBLIC_ABILITIES);

const accessType = Object.freeze({
  coach: 'coach',
  creator: 'creator',
  main: 'main',
  maintenance: 'maintenance',
  manager: 'manager',
  scorekeeper: 'scorekeeper',
  tag: 'tag',
});

const rolePermissions = {
  // ws_s3_storage_size1(user, { can }) {},
  // ws_s3_storage_unlimited(user, { can }) {},

  /**
   *
   * @param {User} user
   * @param {*} param1
   */
  ws_bb_basic_career_stats(user, { can }) {
    can(AclPermissions.read, AclEntities.CareerStats);
    can(AclPermissions.read, AclEntities.BasicCareerStats);
  },
  ws_bb_advanced_career_stats(user, { can }) {
    can(AclPermissions.read, AclEntities.CareerStats);
    can(AclPermissions.read, AclEntities.BasicCareerStats);
    can(AclPermissions.read, AclEntities.AdvancedCareerStats);

  },
  ws_bb_basic_player_reports(user, { can }) {
    can(AclPermissions.read, AclEntities.PlayerReport);
    can(AclPermissions.read, AclEntities.BasicPlayerReport);
  },
  ws_bb_all_player_reports(user, { can }) {
    can(AclPermissions.read, AclEntities.PlayerReport);
    can(AclPermissions.read, AclEntities.BasicPlayerReport);
    can(AclPermissions.read, AclEntities.AdvancedPlayerReport);
  },

  ws_can_use_video_clipper(user, { can }) {
    can(AclPermissions.read, AclEntities.VideoClipper)
  },
  ws_can_try_upload_videos (user, { can }) {
    can(AclPermissions.add, AclEntities.Video)
  },
  ws_bb_can_share(user, { can }) {
    can(AclPermissions.share, AclEntities.Video, { ownerID: { $eq: user.ID} });
  },
  ws_maintenance(user, { can }) {
    can(AclPermissions.manage, AclEntities.All);
  },
  ws_can_view_session_videos(user, { can }) {
    can(AclPermissions.read, AclEntities.Video);
  },
  ws_bb_level_custom(user, { can, cannot }) {
    cannot(AclPermissions.read, AclEntities.Subscription);
    can(AclPermissions.read, AclEntities.Player);
    can(AclPermissions.edit, AclEntities.User);
  },
  ws_bb_can_export_csv(user, { can }) {
    can(AclPermissions.export, AclEntities.Csv);
  },

  ws_bb_can_review_games(user, { can }) {
    can(AclPermissions.read, AclEntities.Session);
  },

  ws_bb_can_review_player_reports(user, { can }) {
  },
  ws_can_view_own_videos(user, { can }) {
    // TODO: check for proper owner
    can(AclPermissions.read, AclEntities.Video, { ownerID: { $eq: user.ID} });
  },
  ws_bb_level0(user, { can }) {
    can(AclPermissions.read, AclEntities.Player);
    can(AclPermissions.list, AclEntities.PublicTeams);
    can(AclPermissions.edit, AclEntities.User);
    can(AclPermissions.edit, AclEntities.Subscription);
  },
  ws_bb_level1(user, { can }) {
    can(AclPermissions.read, AclEntities.Player);
    can(AclPermissions.list, AclEntities.PublicTeams);
    can(AclPermissions.edit, AclEntities.User);
    can(AclPermissions.edit, AclEntities.Subscription);
  },
  ws_bb_level2(user, { can }) {
    can(AclPermissions.read, AclEntities.Player);
    can(AclPermissions.list, AclEntities.PublicTeams);
    can(AclPermissions.edit, AclEntities.User);
    can(AclPermissions.edit, AclEntities.Subscription);
  },
  // ws_bb_can_use_data_filtering(user, { can }) {},
  // ws_bb_can_review_recruiting_reports(user, { can }) {},
  ws_bb_can_review_leaderboards(user, { can }) {
    can(AclPermissions.read, AclEntities.EnhancedLeaderboard);
  },
  ws_bb_can_follow_single_tag(user, { can }) {
    can(AclPermissions.list, AclEntities.ConnectedTag);
    can(AclPermissions.read, AclEntities.ConnectedTag);
    can(AclPermissions.update, AclEntities.ConnectedTag);
  },
  ws_bb_can_follow_many_tags(user, { can }) {
    can(AclPermissions.list, AclEntities.ConnectedTag);
    can(AclPermissions.read, AclEntities.ConnectedTag);
    can(AclPermissions.update, AclEntities.ConnectedTag);
  },
  // ws_bb_can_access_measurements(user, { can }) {},
};

/**
 *
 * @param {User} user
 * @returns {Ability}
 */
function defineAbilitiesFor(user) {
  const builder = new AbilityBuilder(Ability);

  if (user.DisplayName !== '') {
    // logged in
    builder.can(AclPermissions.update, AclEntities.User, { ID: user.ID });

    builder.can(AclPermissions.manage, AclEntities.Session, { ownerID: { $eq: user.ID} });

    builder.can(AclPermissions.delete, AclEntities.Video, { ownerID: { $eq: user.ID} });
  }

  if (user.roles.length) {
    user.roles.forEach((role) => {
      if (typeof rolePermissions[role.Code] === 'function') {
        rolePermissions[role.Code](user, builder);
      } else {
        Vue.$log.debug(`Trying to use unknown role "${role.Code}"`);
      }
    });
  }

  return builder.build();
}

export {
  ability,
  accessType,
  defineAbilitiesFor,
  video,
  session,
  subscription,
  game,
  playerReport,
  league,
  subject,
  PUBLIC_ABILITIES,
};
