<template>
  <div class="snackbar-queue-wrapper">
    <v-snackbar
      v-for="(item, i) in items"
      :key="item.id"
      :value="i === 0"
      :timeout="item.timeout || timeout"
      :color="item.type"
      :top="top"
      :right="right"
      :absolute="absolute"
      :bottom="bottom"
      :left="left"
      :multi-line="!!item.sub"
      :vertical="vertical"
    >
      <v-card-text>
        <div v-html="$vuetify.lang.t(item.message)"></div>
        <pre v-if="item.sub"><code>{{ item.sub }}</code></pre>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="items.length > 1"
          color="white"
          text
          @click="removeItem(item.id)"
        >Next ({{items.length - 1}} more)</v-btn>

        <v-btn icon v-else color="white" text @click="removeItem(item.id)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
    </v-snackbar>
  </div>
</template>

<script>
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'VSnackbarQueue',
  data() {
    return {
      processing: false,
      timeoutId: false,
    };
  },
  props: {
    /**
     * Array for items to display [{id: '', color: '', message: ''}]
     */
    items: {
      type: Array,
      required: true,
    },
    value: {
      required: false,
      default: true,
    },
    timeout: {
      required: false,
      default: 10 * 1000,
    },
    color: {
      required: false,
      default: 'error',
    },
    top: {
      required: false,
      default: false,
    },
    right: {
      required: false,
      default: false,
    },
    absolute: {
      required: false,
      default: false,
    },
    autoHeight: {
      required: false,
      default: false,
    },
    bottom: {
      required: false,
      default: false,
    },
    left: {
      required: false,
      default: false,
    },
    multiLine: {
      required: false,
      default: false,
    },
    vertical: {
      required: false,
      default: false,
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    processItems() {
      const vm = this;
      vm.processing = true;

      if (!isEmpty(vm.items) && vm.timeout) {
        const item = first(vm.items);
        // eslint-disable-next-line no-return-assign
        return (vm.timeoutId = setTimeout(() => {
          vm.removeItem(item.id);
        }, vm.timeout));
      }

      vm.processing = false;
    },
    removeItem(id) {
      this.$log.debug('Id:', id);

      const vm = this;
      // if(vm.timeoutId) {
      //   clearTimeout(vm.timeoutId);
      // }
      vm.$emit('remove', id);

      // if (vm.items.length > 0) {
      //   return vm.processItems();
      // }
    },
  },
  watch: {
    items() {
      // this.processItems();
    },
  },
};
</script>
