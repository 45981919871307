import { actions, mutations } from '@/store/helpers';

const mapper = ({ id, displayName }) => ({ TeamID: id, TeamDisplayName: displayName });

export default {
  state() {
    return {
      fetching: false,
      fetched: false,
      fetchingManaged: false,
      fetchedManaged: false,
    };
  },
  getters: {
    isFetching(state) {
      return state.fetching;
    },
    isFetchingManaged(state) {
      return state.fetchingManaged;
    },
    wasFetched(state) {
      return state.fetched;
    },
    wasFetchedManaged(state) {
      return state.fetchedManaged;
    },
    opponents(state, getters) {
      return getters.all().map(mapper);
    },

    publicTeamsForSeason(state, getters) {
      return (id) => {
        const teams = getters.query().where((team) => {
          return team.seasonID === id && team.isPublic && !team.visibleOnPlayersList;
        }).get();

        return teams.sort((a, b) => a.displayName.localeCompare(b.displayName));
      };
    },

    teamsForSeason(state, getters) {
      return (id) => {
        const teams = getters.query().where((team) => {
          return team.seasonID === id && team.visibleOnPlayersList;
        }).get();

        return teams.sort((a, b) => a.displayName.localeCompare(b.displayName));
      };
    },

    /**
     * @deprecated
     * @param {*} state
     * @param {*} getters
     */
    hasTeams: (state, getters) => !!getters.all().length,
    /**
     * @deprecated
     * @param {*} state
     * @param {*} getters
     */
    allEventTeams: (state, getters) => getters.all(),
    /**
     * @deprecated
     * @param {*} state
     * @param {*} getters
     */
    allEventTeamIDs: (state, getters) => getters.all().map((team) => team.id),
  },

  actions: {
    setFetching: actions.setFetching,
    setFetchingManaged({ commit }, payload) {
      commit('setFetchingManaged', payload);
    },
    setFetched: actions.setFetched,
    setFetchedManaged({ commit }, payload) {
      commit('setFetchedManaged', payload);
    },
  },

  mutations: {
    setFetching: mutations.setFetching,
    setFetchingManaged(state, payload) {
      state.fetchingManaged = payload;
    },
    setFetched: mutations.setFetched,
    setFetchedManaged(state, payload) {
      state.fetchedManaged = payload;
    },
  },
};
