const STATES = {};

STATES.LOADING = 'LOADING';
STATES.SUCCESS = 'SUCCESS';
STATES.ERROR = 'ERROR';
STATES.AUTHORIZED = 'AUTHORIZED';
STATES.UNAUTHORIZED = 'UNAUTHORIZED';
STATES.EMPTY = '';
STATES.PENDING = 'PENDING';
STATES.MANUAL = 'MANUAL';
STATES.ACCEPTED = 'ACCEPTED';
STATES.ENABLED = 'ENABLED';

export default STATES;
