import { Model } from '@vuex-orm/core';
import { League } from './Hierarchy';

export class Season extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'seasons';

  static state() {
    return {
      fetching: false,
    };
  }

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.number(null),
      league: this.belongsTo(League, 'leagueID'),
      startDate: this.string(''),
      endDate: this.string(''),
      displayName: this.string(''),
      creatorID: this.number(null),
      leagueID: this.number(null),
    };
  }
}

export default Season;
