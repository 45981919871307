import { Model } from '@vuex-orm/core';
// import { Role } from './Hierarchy';

export class Role extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'roles';

  static primaryKey = 'ID';

  static state() {
    return {
      fetching: false,
    };
  }

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      user_id: this.number(null),
      Code: this.string(''),
      Expires: this.string(''),
      ExpiresOverdue: this.string(''),
      ID: this.string(''),
      IsExpired: this.string(''),
      RoleDisplayName: this.string(''),
      StartDate: this.string(''),
    };
  }
}

export default Role;
