import Vue from 'vue';
import VueGtag from 'vue-gtag';

import { IS_PRODUCTION } from '../constants';
import router from '../router';

if (process.env.VUE_APP_UA_ID) {
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_UA_ID },
    appName: process.env.VUE_APP_NAME, // Mandatory
    appVersion: process.env.VUE_APP_VERSION, // Mandatory
  }, router);
}
