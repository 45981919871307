import Vue from 'vue';
import { Providers, Enums } from '@flightscope/baseball-stats';
import PlayerPosition from '@/enums/PlayerPosition';
import { convertUnit, unitSymbolProvider, convertUnitNoSymbol } from '@/filters/units';
import { utcToLocal } from '@/utils/date_n_time';
import { enumerate } from '@/filters/enums';
import textCapitalize from '@/filters/text_formatters';
import { numberOrHyphen, defNumber } from '@/filters/number_render';


// filters
Vue.filter('convertUnit', convertUnit);
Vue.filter('getUnitSymbol', unitSymbolProvider);
Vue.filter('convertUnitNoSymbol', convertUnitNoSymbol);
Vue.filter('numberOrHyphen', numberOrHyphen);
Vue.filter('defNumber', defNumber);
Vue.filter('enumerate', enumerate);

Vue.filter('utcToLocal', utcToLocal);

Vue.filter('capitalize', textCapitalize);

Vue.filter('playerPosition', (value) => {
  const result = Providers.EnumValueProvider.getValue(
    value,
    PlayerPosition,
    PlayerPosition.Unidentified.key,
    null,
    'name'
  );

  if (result === PlayerPosition.Unidentified.name) {
    return '-';
  }

  return result;
});

Vue.filter('handedness', (value) => {
  const result = Providers.EnumValueProvider.getValue(
    value,
    Enums.Handedness,
    Enums.Handedness.Unidentified.key,
    null,
    'name'
  );

  if (result === Enums.Handedness.Unidentified.name) {
    return '-';
  }

  return result;
});
