import Vue from 'vue';
import { abilitiesPlugin, Can } from '@casl/vue';
import { ability } from '@/services/ability';


Vue.use(abilitiesPlugin, ability);
Vue.component('Can', Can);

if (!process.env.PRODUCTION) {
  //! FIXME: the next line is added for debugging purposes and should be removed for production build
  window.ability = ability;
}
