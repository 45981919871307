import { Model } from '@vuex-orm/core';
import playerNameFilter from '@/filters/playerNameFilter';
import { Team, ConnectedTag } from '@/models/orm/Hierarchy';
import { Enums, Providers } from '@flightscope/baseball-stats';
import PlayerPosition from '@/enums/PlayerPosition';

export class Player extends Model {
  // TODO - unify that with PlayerModel and ConnectedTag
  static entity = 'players';

  static state() {
    return {
      fetching: false,
      fetched: false,
    };
  }

  static fields() {
    return {
      id: this.number(null),
      teamID: this.number(null),
      creatorID: this.number(null),
      leagueID: this.number(null),
      parameters: this.attr(null),
      dateCreated: this.string(''),
      dateModified: this.string(''),
      externalID: this.string('').nullable(),
      isDefault: this.boolean(false),
      // TODO - switch to relationship with separate model, eg  this.hasMany(RosterPlayer)
      RosterPlayers: this.attr([]),

      team: this.belongsTo(Team, 'teamID'),

      connectedTag: this.hasOne(ConnectedTag, 'playerTagID'),
    };
  }

  get exists() {
    return this.RosterPlayers.some( p => p.isDeleted === false);
  }

  /**
   * Parameters set via DTO
   * @see https://bitbucket.org/flightscope/dto-schema/src/40de023beb9e7cc40a7527ce5954b99b2ce1a023/flightscope/playertag-parameter.json?at=feature/baseball
   *
   */

  get FirstName() {
    return this.parameters?.FirstName ?? '';
  }

  get LastName() {
    return this.parameters?.LastName ?? '';
  }

  get DisplayName() {
    return this.parameters?.DisplayName ?? '';
  }

  get BirthDate() {
    return this.parameters?.BirthDate ?? null;
  }

  get Height() {
    return this.parameters?.Height || null;
  }

  get Weight() {
    return this.parameters?.Weight || null;
  }

  get AvatarURL() {
    return this.parameters?.AvatarURL ?? undefined;
  }

  get AvatarBase64() {
    return this.parameters?.AvatarBase64 ?? '';
  }

  get Gender() {
    return this.parameters?.Gender ?? null;
  }

  get Address() {
    return this.parameters?.Address ?? null;
  }

  get School() {
    return this.parameters?.School ?? null;
  }

  get GraduationYear() {
    return this.parameters?.GraduationYear ?? null;
  }

  get Email() {
    return this.parameters?.Email ?? null;
  }

  /**
   * Parameters set via DTO
   * @see https://bitbucket.org/flightscope/dto-schema/src/40de023beb9e7cc40a7527ce5954b99b2ce1a023/baseball/entity/playertag-parameter.json?at=feature/baseball
   *
   */
  get BatHandedness() {
    return Providers.EnumValueProvider.getValue(
      this.parameters?.BatHandedness,
      Enums.Handedness,
      Enums.Handedness.Unidentified.key,
      null,
      'key'
    );
  }

  get ThrowHandedness() {
    return Providers.EnumValueProvider.getValue(
      this.parameters?.ThrowHandedness,
      Enums.Handedness,
      Enums.Handedness.Unidentified.key,
      null,
      'key'
    );
  }

  // unify with PlayerModel
  get Bats() {
    return this.BatHandedness;
  }

  get Throws() {
    return this.ThrowHandedness;
  }

  get PrimaryPosition() {
    if (!this.hasRosterPlayers || !this.recentRosterPlayer || !Array.isArray(this.recentRosterPlayer?.position) || this.recentRosterPlayer.position.length < 1) {
      if (this.parameters?.Position) {
        if (Array.isArray(this.parameters?.Position)) {
          return this.parameters?.Position[0] || PlayerPosition.Unidentified.key;
        }

        return this.parameters?.Position || PlayerPosition.Unidentified.key;
      }
    }

    return this.recentRosterPlayer.position[0] || PlayerPosition.Unidentified.key;
  }

  get SecondaryPosition() {
    if (!this.hasRosterPlayers || !this.recentRosterPlayer || !Array.isArray(this.recentRosterPlayer?.position) || this.recentRosterPlayer.position.length < 2) {
      return this.parameters?.Position && Array.isArray(this.parameters?.Position)
        ? this.parameters?.Position[1] : PlayerPosition.Unidentified.key;
    }

    return this.recentRosterPlayer.position[1] || PlayerPosition.Unidentified.key;
  }

  get Number() {
    if (!this.hasRosterPlayers || !this.recentRosterPlayer || !this.recentRosterPlayer.number || this.recentRosterPlayer.number === '-') {
      return this.parameters?.Number || '';
    }

    return this.recentRosterPlayer.number || '';
  }

  get TagID() {
    return this.id;
  }

  /*
  Custom accessors
  */
  /**
   * Used in this.FormattedName internal function
   *
   * @see playerNameFilter
   */
  get JerseyNumber() { return this.Number; }

  /**
   * @see playerNameFilter
   */
  get FormattedName() {
    return playerNameFilter(process.env.VUE_APP_PLAYER_NAME_FORMAT, this).trim();
  }

  /**
   * @see playerNameFilter
   */
  get FormattedNameWithJersey() {
    return playerNameFilter(process.env.VUE_APP_PLAYER_NAME_FORMAT, this, this.Number).trim();
  }

  get hasRosterPlayers() {
    return this.RosterPlayers.length > 0;
  }

  get recentRosterPlayer() {
    return this.RosterPlayers[0];
  }

  // get VideoCount() {return this.parameters?.VideoCount ?? 0;}

  // get Avatar() {return this.AvatarURL;}

  // get City() {return this.parameters?.City ?? '';}

  // get Country() {return this.parameters?.Country ?? '';}

  // get HighSchool() {return this.parameters?.HighSchool ?? '';}

  // get Id() {return this.id;}

  // get State() {return this.parameters?.State ?? '';}
}

export default Player;
