import { Model } from '@vuex-orm/core';
import { Player } from '@/models/orm/Hierarchy';
import PlayerPosition from '@/enums/PlayerPosition'

// https://bitbucket.org/flightscope/dto-schema/src/b0a7e7f7c0a3e307e750a4bd2cac8da0706f8109/baseball/entity/bench-tag.json?at=feature/baseball
export class BenchTag extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'benchTags';

  static state() {
    return {
      fetching: false,
    };
  }

  static fields() {
    return {
      id: this.uid(),
      lineup_id: this.number(null),
      tagID: this.number(null),
      position: this.string(''),
      height: this.number(null).nullable(),

      player: this.belongsTo(Player, 'tagID'),
    };
  }

  get isDesignatedHitter() {
    return this.position === PlayerPosition.DesignatedHitter.key;
  }

  get isPitcher() {
    return this.position === PlayerPosition.Pitcher.key;
  }

  get PrimaryPosition() {
    if (this.position && this.position !== PlayerPosition.Unidentified.key) {
      return this.position;
    }
    if (this.player) {
      return this.player.PrimaryPosition;
    }
    return this.position;
  }

  get AvatarURL() {
    return this?.player?.AvatarURL ||'';
  }

  get FormattedName() {
    return this?.player?.FormattedName || `Bench #${this.tagID}`;
  }
}

export default BenchTag;
