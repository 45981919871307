export const SESSIONS_REQUEST = 'SESSIONS_REQUEST';
export const SESSIONS_SUCCESS = 'SESSIONS_SUCCESS';
export const SESSIONS_ERROR = 'SESSIONS_ERROR';
export const SET_FILTER = 'SET_FILTER';
export const SET_SEASON = 'SET_SEASON';
export const SET_DATES = 'SET_DATES';
export const SET_PLACE = 'SET_PLACE';
export const SET_TEAM = 'SET_TEAM';
export const SET_PAGE = 'SET_PAGE';
export const SET_OPPONENTS = 'SET_OPPONENTS';
export const SET_ATTENDANTS = 'SET_ATTENDANTS';
export const SESSIONS_FILTER_CLEAN = 'SESSIONS_FILTER_CLEAN';
export const CLEAR_SESSION_LIST = 'CLEAR_SESSION_LIST';
export const ERASE_SESSIONS = 'ERASE_SESSIONS';
