import { Model } from '@vuex-orm/core';

/**
 * @see {@link '@flightscope/dto-schema/flightscope/media.json'}
 */
export class Media extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'media';

  static state() {
    return {
      fetching: false,
    };
  }

  static fields() {
    return {
      id: this.number(null),
      ownerID: this.number(null),
      mediaTypeID: this.number(null),
      createDate: this.string(''),
      ts: this.string(''),
      metadata: this.attr(null),

      mediaType: this.string('').nullable(),
      path: this.string('').nullable(),
      displayName: this.string('').nullable(),
      totalByteSize: this.number(null).nullable(),
      masterMediaID: this.number(null).nullable(),
      isDeleted: this.boolean(false).nullable(),
      starredDate: this.string('').nullable(),
    };
  }

  get isVideo() {
    return this.mediaType === 'Video';
  }
}

export default Media;
