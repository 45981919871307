import { Model } from '@vuex-orm/core';

export class Result extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'results';

  static state() {
    return {
      fetching: false,
    };
  }

  static fields() {
    return {
      id: this.number(null),
      pitchResultID: this.number(null).nullable(),
      hitResultID: this.number(null).nullable(),
      sessionID: this.number(null),
      resultType:  this.string(''),
      createDate: this.string(''),
      isInvalid: this.number(null),
      pitcherTagID: this.number(null).nullable(),
      batterTagID: this.number(null).nullable(),
      data: this.attr(null),
      isDeleted: this.boolean(false),
      invalidData: this.attr(null).nullable(),
      failedValidations: this.attr(null).nullable(),
      sanitization: this.attr(null).nullable(),
      media: this.attr(null).nullable(),
    };
  }
}

export default Result;
