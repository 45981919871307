export const CLEAR_PLAYER_LIST = 'CLEAR_PLAYER_LIST';
export const ERASE_PLAYERS = 'ERASE_PLAYERS';
export const PLAYERS_ERROR = 'PLAYERS_ERROR';
export const PLAYERS_FILTER_CLEAN = 'PLAYERS_FILTER_CLEAN';
export const PLAYERS_REQUEST = 'PLAYERS_REQUEST';
export const PLAYERS_SUCCESS = 'PLAYERS_SUCCESS';
export const SET_BAT_HANDEDNESS = 'SET_BAT_HANDEDNESS';
export const SET_FILTER = 'SET_FILTER';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_NAME = 'SET_NAME';
export const SET_PAGE = 'SET_PAGE';
export const SET_THROW_HANDEDNESS = 'SET_THROW_HANDEDNESS';
export const SET_POSITION = 'SET_POSITION';
export const SET_SEASON = 'SET_SEASON';
export const SET_TEAM = 'SET_TEAM';
export const SET_PLAYERS_SEARCH_FORCING = 'SET_PLAYERS_SEARCH_FORCING';
