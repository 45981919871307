import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import Vue2Filters from 'vue2-filters';

import system from './modules/system/module';
import Router from './router';
import Store from './store';

import { IS_PRODUCTION } from './constants';
import App from './App.vue';
import './registerServiceWorker';

import vuetify from './plugins/vuetify';

import useRecaptcha from './plugins/recaptcha';
import useSentry from './plugins/sentry';
import './plugins/logger';
import './plugins/meta';
import './plugins/filters';
import './plugins/gtag';
import './plugins/stripe';
import './plugins/async-computed';
import './plugins/vue-casl-ability';

import './components/_globals';

import 'roboto-fontface/css/roboto/sass/roboto-fontface.scss';
import '@mdi/font/css/materialdesignicons.css';
import './styles/main.scss';

// Use recaptcha only when VUE_APP_CAPTCHA_SITE_KEY is defined in env
if (process.env.VUE_APP_CAPTCHA_SITE_KEY) {
  useRecaptcha();
}

if (process.env.VUE_APP_SENTRY_DSN && ['stage', 'production'].includes(process.env.NODE_ENV)) {
  useSentry(Vue, Router);
}

Vue.config.productionTip = false;

Vue.config.devtools = !!(process.env.VUE_APP_DEVTOOLS || false);

Vue.use(Vue2Filters);

Store.registerModule('system', system.store);

system.routes.forEach((route) => {
  Router.addRoute(route);
});

Store.dispatch('system/initialize', null, { root: true }).then(() => {
  const app = new Vue({
    provide: {
      isProduction: IS_PRODUCTION,
      debug: process.env.VUE_APP_DEBUG,
    },
    store: Store,
    router: Router,
    vuetify,
    mixins: [Vue2Filters.mixin],
    render: (h) => h(App),
  });
  app.$mount('#app');
});
