const ResultFilter = {
  Pitchers: { key: 'pitchers', name: 'Pitchers' },
  Pitcher: { key: 'pitcher', name: 'Pitcher', filterKey: 'pitcherId' },
  PitcherHandedness: { key: 'pitcher-handedness', name: 'Pitcher Handedness', filterKey: 'pitcherHandedness' },
  PitchSet: { key: 'pitch-set', name: 'Pitch Set', filterKey: 'pitchSet' },
  PitchResult: { key: 'pitch-result', name: 'Pitch Call', filterKey: 'pitchResults' },
  PitchCall: { key: 'pitch-result', name: 'Pitch Call', filterKey: 'pitchResults' },
  PlayOutcome: { key: 'play-outcome', name: 'Play Outcome', filterKey: 'pitchResults' },
  PitchType: { key: 'pitch-type', name: 'Pitch Type', filterKey: 'pitchTypes' },
  Batters: { key: 'batters', name: 'Batters' },
  Batter: { key: 'batter', name: 'Batter', filterKey: 'batterId' },
  BatterHandedness: { key: 'batter-handedness', name: 'Batter Handedness', filterKey: 'batterHandedness' },
  Zones: { key: 'zones', name: 'Zone' },
  AttackRegion: { key: 'attack-region', name: 'Attack Region' },
  ScoringOuts: { key: 'scoring-outs', name: 'Outs', filterKey: 'outs' },
  ScoringCounts: { key: 'scoring-counts', name: 'Counts', filterKey: 'counts' },
  ScoringBalls: { key: 'scoring-balls', name: 'Balls', filterKey: 'balls' },
  ScoringStrikes: { key: 'scoring-strikes', name: 'Strikes', filterKey: 'strikes' },
  ScoringRunnersOn: { key: 'scoring-runners', name: 'Runners on', filterKey: 'runnersOn' },
  PitchParameter: { key: 'pitch-parameter', name: 'Parameter', filterKey: 'pitchParameter' },
  HitParameter: { key: 'hit-parameter', name: 'Parameter', filterKey: 'hitParameter' },
};

export default ResultFilter;
