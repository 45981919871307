import { Model } from '@vuex-orm/core';
import { accessType } from '@/services/ability';
import { User, League, Season, Player } from './Hierarchy';
import { getTeamShortCode } from '../../store/modules/sessions/tools/helpers';

export class Team extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'teams';

  static testArr = [
    accessType.maintenance,
    accessType.creator,
    accessType.scorekeeper,
    accessType.manager,
    // accessType.tag
  ];

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      acceptTracking: this.boolean(false),
      ageLimit: this.number(null),
      city: this.string('').nullable(),
      createDate: this.string(''),
      creatorID: this.number(null),
      displayName: this.string(''),
      division: this.string('').nullable(),
      externalId: this.string('').nullable(),
      id: this.number(null),
      isDeleted: this.boolean(null),
      isOfficial: this.boolean(null),
      isPublic: this.boolean(null),
      leagueID: this.number(null),
      level: this.number(null),
      localName: this.string('').nullable(),
      logo: this.string('').nullable(),
      logoBase64: this.string('').nullable(),
      parentID: this.number(null),
      school: this.string('').nullable(),
      seasonID: this.number(null),
      shortCode: this.string('').nullable(),
      stadium: this.string('').nullable(),
      state: this.string('').nullable(),
      ts: this.string(''),

      // 2021-04-14 Added after visible change in response
      category: this.string('').nullable(),
      coaches: this.attr(null).nullable(),
      access: this.string('').nullable(),

      fetchedPlayers: this.boolean(false),

      // TODO: check if needed
      treneiros: this.hasManyBy(User, 'coaches'),
      league: this.belongsTo(League, 'leagueID'),
      season: this.belongsTo(Season, 'seasonID'),
      players: this.hasMany(Player, 'teamID'),
    };
  }

  /**
   * Parameters set via DTO
   * @see https://bitbucket.org/flightscope/dto-schema/src/40de023beb9e7cc40a7527ce5954b99b2ce1a023/baseball/entity/team-v2.json?at=feature/baseball
   *
   */
  get ShortCode() {
    return this.shortCode || getTeamShortCode(this.displayName);
  }

  get followable() {
    return Team.testArr.includes(this.access) === false;
  }

  get visibleOnPlayersList() {
    return Team.testArr.includes(this.access);
  }

  get name() {
    return this.displayName;
  }
}

export default Team;
