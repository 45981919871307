import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { get, call } from 'vuex-pathify';
import Repository from '@/repositories/RepositoryFactory';
import { REMOVE_ALERT } from './store/actions/alerts';
import { initialBootstrap } from './modules/core/initial';

export default defineComponent({
  name: 'App',

  components: {
    TosDialog: () => import('@/modules/user-profile/components/TosDialog.vue'),
    TrialAlert: () => import('@/components/ui/alerts/TrialAlert.vue'),
  },

  provide: {
    repo: Repository,
  },

  inject: {
    bootstrap: {
      type: Function,
      default: () => initialBootstrap,
    },
  },

  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Welcome',
    // all titles will be injected into this template
    titleTemplate: `%s | ${process.env.VUE_APP_NAME} v${process.env.VUE_APP_VERSION}`,
  },

  data() {
    return {
      refreshing: false,
      registration: null,
      snackBtnText: '',
      snackWithBtnText: '',
      snackWithButtons: false,
      timeout: -1,
      unsubscribe: null,

      dialogProps: null,
      dialogComponent: null,
      dialogComponentProps: null,
    };
  },

  async created() {
    this.$log.debug('App component created');

    this.serviceWorkerInit();

    this.load();

    this.bootstrap();
  },

  mounted() {
    this.$log.debug('App component mounted');
    // document.addEventListener('swUpdated', this.showRefreshUI);
  },

  computed: {
    ...mapGetters(['isProfileLoaded', 'isAuthorizing', 'isAuthorized', 'currentProfile']),

    appLoading: get('app/isLoading'),
    alerts() {
      return this.$store.state.alerts.list;
    },
    alert_index() {
      return this.$store.state.alerts.index;
    },
    status() {
      return this.$store.state.auth.status;
    },

    showTosDialog() {
      return this.isProfileLoaded && !this.currentProfile?.Parameters?.TosAcceptedOn;
    },

    sportType() {
      return `${process.env.VUE_APP_SPORT_TYPE.toLowerCase()}-portal`;
    },

    routeName() {
      return `route-${this.$route.name}`;
    }
  },

  methods: {
    load: call('app/load'),
    loaded: call('app/loaded'),
    removeAlert(id) {
      this.$store.dispatch(REMOVE_ALERT, id);
    },

    serviceWorkerInit() {
      const vm = this;
      if ('serviceWorker' in navigator) {
        // Listen for swUpdated event and display refresh snackbar as required.
        document.addEventListener('swUpdated', this.showRefreshUI);
        // Refresh all open app tabs when a new service worker is installed.
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (this.refreshing) return;
          vm.refreshing = true;
          window.location.reload();
        });
      }
    },

    showRefreshUI(e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
      this.snackBtnText = 'Refresh';
      this.snackWithBtnText = 'New version available!';
      this.snackWithButtons = true;
    },

    refreshApp() {
      this.snackWithButtons = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
  },
});
