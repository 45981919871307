import { Providers, Enums } from '@flightscope/baseball-stats';
import { GenericPitchType } from '@/enums/Generic';
import playerNameFilter from '@/filters/playerNameFilter';
import { convertUnitNoSymbol } from '@/filters/units';
import ResultFilter from '@/filters/ResultFilter';
import PlayerPosition from '@/enums/PlayerPosition';
import {
  EPitchSet, EHandedness, EPitchResult, ERunnersOn, EPOuts, EPitchCallExtTag, EPlateAppearanceResultExtTag
} from '@/filters/enums';

import PlayerRepo from '@/repositories/PlayerRepository';

/* eslint-disable no-param-reassign */

/** @type SessionPlayer */
export const defaultSessionPlayer = {
  UId: 'placeholder-0',
  Id: 0,
  Type: 'placeholder',
  Avatar: process.env.VUE_APP_DEFAULT_PLAYER_AVATAR,
  FirstName: '',
  LastName: '-',
  DisplayName: '-',
  FormattedName: '-',
  FormattedNameWithJersey: '',
  JerseyNumber: '',
  Position: 'UN',
  Bats: '',
  Throws: '',
  Height: 0,
  Weight: 0,
  TeamId: 0,
};
export function defaultSessionPlayerWithID(tagID, playerID) {
  return {
    UId: `placeholder-${playerID}-${tagID}`,
    Id: tagID || playerID,
    Type: 'placeholder',
    Avatar: process.env.VUE_APP_DEFAULT_PLAYER_AVATAR,
    FirstName: '',
    LastName: '-',
    DisplayName: '-',
    FormattedName: `unknown #${tagID ?? playerID}`,
    FormattedNameWithJersey: '',
    JerseyNumber: '',
    Position: 'UN',
    Bats: '',
    Throws: '',
    Height: NaN,
    Weight: NaN,
  };
}

export function wsRosterPlayerToSessionPlayer(
  tagData,
  sessionPlayer = defaultSessionPlayerWithID(tagData.TagID),
) {
  const jerseyNumber = tagData.Tag.Parameters.Number || sessionPlayer.JerseyNumber || '';
  sessionPlayer.Id = parseInt(tagData.TagID, 10);
  sessionPlayer.Type = 'tag';
  sessionPlayer.Avatar = tagData.Tag.Parameters.AvatarURL || sessionPlayer.Avatar;
  sessionPlayer.LastName = tagData.Tag.Parameters.LastName || sessionPlayer.LastName;
  sessionPlayer.FirstName = tagData.Tag.Parameters.FirstName || sessionPlayer.FirstName;
  sessionPlayer.DisplayName = tagData.Tag.Parameters.DisplayName || sessionPlayer.DisplayName;
  sessionPlayer.FormattedName = playerNameFilter(process.env.VUE_APP_PLAYER_NAME_FORMAT, sessionPlayer);
  sessionPlayer.FormattedNameWithJersey = playerNameFilter(process.env.VUE_APP_PLAYER_NAME_FORMAT, sessionPlayer, jerseyNumber);
  sessionPlayer.JerseyNumber = jerseyNumber;
  sessionPlayer.Position = tagData.Tag.Parameters.Position || sessionPlayer.Position;
  sessionPlayer.Bats = tagData.Tag.Parameters.BatHandedness || sessionPlayer.Bats;
  sessionPlayer.Throws = tagData.Tag.Parameters.ThrowHandedness || sessionPlayer.Throws;
  sessionPlayer.Height = parseFloat(tagData.Tag.Parameters.Height || sessionPlayer.Height) || NaN;
  sessionPlayer.Weight = parseFloat(tagData.Tag.Parameters.Weight || sessionPlayer.Weight) || NaN;

  return sessionPlayer;
}

export function mapOptionsToSelect(selectionType, sessionData) {
  let output = [];
  let unique = [];
  switch (selectionType) {
    case ResultFilter.PlayOutcome.key:
      {
        const pitchResultCombinedGroupArr = [
          Enums.PitchResultCombinedGroup.BaseHits,
          Enums.PitchResultCombinedGroup.BatterOut,
          Enums.PitchResultCombinedGroup.BatterSafe,
          Enums.PitchResultCombinedGroup.NoResult,
        ];

        output = pitchResultCombinedGroupArr.map(({ key, name, results }) => {
          const items = results.map((key) => {
            const item = Providers.EnumValueProvider.getValue(key, Enums.PitchResultCombined);
            if (
              sessionData.hasExtendedTagging
              && (
                EPitchCallExtTag.findIndex(pc => pc.value === item.pitchCall) === -1
                || EPlateAppearanceResultExtTag.findIndex(par => par.value === item.plateAppearanceResult) === -1
              )
            ) {
              return null;
            }

            return {
              disabled: false,
              text: item.name,
              value: item.key,
            };
          }).filter(Boolean);

          return {
            active: false,
            key,
            name,
            items,
          };
        });
      }
      break;
    case ResultFilter.PitchSet.key:
      unique = [...new Set(sessionData.Results.map((item) => item.Data.PITCH_MECHANICS))];
      output = EPitchSet.filter((item) => {
        return item.value == '' || unique.includes(item.value);
      });
      break;
    case ResultFilter.BatterHandedness.key:
      // unique = [...new Set(sessionData.Results.map(item => item.Data.PITCH_MECHANICS))];
      output = EHandedness;
      break;
    case ResultFilter.PitcherHandedness.key:
      // unique = [...new Set(sessionData.Results.map(item => item.Data.PITCH_MECHANICS))];
      output = EHandedness;
      break;
    case ResultFilter.PitchResult.key:
      output = EPitchResult;
      break;
    case ResultFilter.ScoringOuts.key:
      output = [{ text: 'No filter selected', value: '' }, ...EPOuts];
      break;
    case ResultFilter.ScoringRunnersOn.key:
      output = [{ text: 'No filter selected', value: '' }, ...ERunnersOn];
      break;
    default:
    // NOOP
  }
  return output;
}

export function defaultPlayer() {
  return {
    AvatarURL: process.env.VUE_APP_DEFAULT_PLAYER_AVATAR,
    BatHandedness: '',
    Bats: '',
    BirthDate: NaN,
    DisplayName: '',
    FirstName: '',
    Height: NaN,
    LastName: '',
    Number: '',
    JerseyNumber: '',
    Position: '',
    PrimaryPosition: '',
    SecondaryPosition: '',
    Season: NaN,
    TeamDisplayName: '',
    TeamLogoURL: '/img/avatar/default_team.jpg',
    ThrowHandedness: '',
    Throws: '',
    Weight: NaN,
  };
}

export function wsPlayerToPlayerInList(player, season, _defaultPlayer = defaultPlayer()) {
  player.AvatarURL = player.AvatarURL || _defaultPlayer.AvatarURL;
  player.BatHandedness = Providers.EnumValueProvider.getValue(
    player.BatHandedness,
    Enums.Handedness,
    Enums.Handedness.Unidentified.key,
  ).key;
  player.BirthDate = player.BirthDate || _defaultPlayer.BirthDate;
  player.DisplayName = player.DisplayName || _defaultPlayer.DisplayName;
  player.FirstName = player.FirstName || _defaultPlayer.FirstName;
  player.FormattedName = playerNameFilter(process.env.VUE_APP_PLAYER_NAME_FORMAT, player);
  player.Height = player.Height || _defaultPlayer.Height;
  player.LastName = player.LastName || _defaultPlayer.LastName;
  player.Number = player.Number || _defaultPlayer.Number;
  player.Position = Providers.EnumValueProvider.getValue(
    player.Position,
    PlayerPosition,
    PlayerPosition.Unidentified.key,
  ).name;
  player.TeamDisplayName = player.TeamDisplayName || _defaultPlayer.TeamDisplayName;
  player.TeamLogoURL = player.TeamLogoURL || _defaultPlayer.TeamLogoURL;
  player.ThrowHandedness = Providers.EnumValueProvider.getValue(
    player.ThrowHandedness,
    Enums.Handedness,
    Enums.Handedness.Unidentified.key,
  ).key;
  player.Weight = player.Weight || _defaultPlayer.Weight;
  player.Season = season || _defaultPlayer.Season;

  return player;
}

export const getSummaryStatsItemByEnum = (item, _enum, unitSystem, options = {}) => ({
  [_enum.key]: convertUnitNoSymbol(item[_enum.key], _enum.type, unitSystem, Object.assign({
    noValueFallback: '-',
  }, options)),
});

function mapPlayer(id, players) {
  if (id && Array.isArray(players) && players.length) {
    const player = players.find(p => p.id === id);
    return player;
  }

  return PlayerRepo.getDefault(id);
}

export function mapHittingSummaryStats(playerStats, unitSystem, players) {
  return playerStats.map((item) => ({
    PlateAppearance: item.PlateAppearance,
    NumberOfPitches: item.NumberOfPitches,

    PitchThrown: Providers.EnumValueProvider.getValue(item.PitchThrown, Enums.Handedness, 'U').name,
    PitchType: Providers.EnumValueProvider.getValue(item.PitchType, GenericPitchType, 'UI').name,
    PitchResult: Providers.EnumValueProvider.getValue(item.PitchResult, Enums.PitchResult, 'UI').name,
    PitchSpeed: convertUnitNoSymbol(item.PitchSpeed, Enums.PitchData.Speed.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 2,
    }),
    BattedBallType: item.FinalHitId
      ? Providers.EnumValueProvider.getValue(item.BattedBallType, Enums.BattedBallType, 'UI').name
      : '-',
    StrikeZoneSector: item.StrikeZoneSector,
    ExitSpeed: convertUnitNoSymbol(item.ExitSpeed, Enums.HitData.ExitSpeed.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 2,
    }),
    MAXExitSpeed: convertUnitNoSymbol(item.MaxExitSpeed, Enums.HitData.ExitSpeed.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 2,
    }),
    CarryDistance: convertUnitNoSymbol(item.CarryDistance, Enums.HitData.CarryDistance.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 2,
    }),
    HitLaunchV: convertUnitNoSymbol(item.HitLaunchV, Enums.HitData.LaunchV.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 2,
    }),
    HangTime: convertUnitNoSymbol(item.HangTime, Enums.HitData.FlightTime.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 1,
    }),

    FinalPitchId: item.FinalPitchId,
    FinalHitId: item.FinalHitId,

    PitcherId: item.PitcherId,
    PitcherObj: mapPlayer(item.PitcherId, players),
  }));
}

export function mapPitchingSummaryStats(pitcherStats, unitSystem) {
  return pitcherStats.map(item => ({
    PitchType: item.pitchType,
    PitchTypeLabel: Providers.EnumValueProvider.getValue(item.pitchType, GenericPitchType, 'UI').name,
    [Enums.PitchingStats.NumberOfPitches.key]: item[Enums.PitchingStats.NumberOfPitches.key],
    [Enums.PitchingStats.PitchUsage.key]: convertUnitNoSymbol(item[Enums.PitchingStats.PitchUsage.key], Enums.PitchingStats.PitchUsage.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 1,
    }),
    [Enums.PitchingStats.PitchSpeedAvg.key]: convertUnitNoSymbol(item[Enums.PitchingStats.PitchSpeedAvg.key], Enums.PitchingStats.PitchSpeedAvg.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 1,
    }),
    [Enums.PitchingStats.PitchSpeedMax.key]: convertUnitNoSymbol(item[Enums.PitchingStats.PitchSpeedMax.key], Enums.PitchingStats.PitchSpeedMax.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 1,
    }),
    [Enums.PitchingStats.PitchSpinAvg.key]: convertUnitNoSymbol(item.PitchSpinAvg, Enums.PitchingStats.PitchSpinAvg.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 0,
    }),
    [Enums.PitchingStats.PitchMovementHAvg.key]: convertUnitNoSymbol(
      item[Enums.PitchingStats.PitchMovementHAvg.key],
      Enums.PitchingStats.PitchMovementHAvg.type,
      unitSystem,
      { noValueFallback: '-', fractionalDigits: 1 },
    ),
    [Enums.PitchingStats.PitchMovementVAvg.key]: convertUnitNoSymbol(
      item[Enums.PitchingStats.PitchMovementVAvg.key],
      Enums.PitchingStats.PitchMovementVAvg.type,
      unitSystem,
      { noValueFallback: '-', fractionalDigits: 1 },
    ),
    [Enums.PitchingStats.ExtensionAvg.key]: convertUnitNoSymbol(item[Enums.PitchingStats.ExtensionAvg.key], Enums.PitchingStats.ExtensionAvg.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 1,
    }),
    [Enums.PitchingStats.KZoneRate.key]: convertUnitNoSymbol(item[Enums.PitchingStats.KZoneRate.key], Enums.PitchingStats.KZoneRate.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 1,
    }),
    [Enums.PitchingStats.HitExitSpeedAvg.key]: convertUnitNoSymbol(item[Enums.PitchingStats.HitExitSpeedAvg.key], Enums.PitchingStats.HitExitSpeedAvg.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 1,
    }),
    [Enums.PitchingStats.HitsAllowed.key]: item[Enums.PitchingStats.HitsAllowed.key],
    [Enums.PitchingStats.Strikeouts.key]: item[Enums.PitchingStats.Strikeouts.key],
    [Enums.PitchingStats.StrikeoutRate.key]: convertUnitNoSymbol(item[Enums.PitchingStats.StrikeoutRate.key], Enums.PitchingStats.StrikeoutRate.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 1,
    }),
    [Enums.PitchingStats.Whiffs.key]: item[Enums.PitchingStats.Whiffs.key],
    [Enums.PitchingStats.Swings.key]: item[Enums.PitchingStats.Swings.key],
    [Enums.PitchingStats.SwingRate.key]: convertUnitNoSymbol(item.SwingRate, Enums.PitchingStats.SwingRate.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 1,
    }),
    [Enums.PitchingStats.WhiffRate.key]: convertUnitNoSymbol(item.WhiffRate, Enums.PitchingStats.WhiffRate.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 1,
    }),
    [Enums.PitchingStats.SwingingStrikeRate.key]: convertUnitNoSymbol(item.SwingingStrikeRate, Enums.PitchingStats.SwingingStrikeRate.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 1,
    }),
    [Enums.PitchingStats.ZSwingRate.key]: convertUnitNoSymbol(item.ZSwingRate, Enums.PitchingStats.ZSwingRate.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 1,
    }),
    [Enums.PitchingStats.ZContactRate.key]: convertUnitNoSymbol(item.ZContactRate, Enums.PitchingStats.ZContactRate.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 1,
    }),
    [Enums.PitchingStats.OSwingRate.key]: convertUnitNoSymbol(item.OSwingRate, Enums.PitchingStats.OSwingRate.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 1,
    }),
    [Enums.PitchingStats.OContactRate.key]: convertUnitNoSymbol(item.OContactRate, Enums.PitchingStats.OContactRate.type, unitSystem, {
      noValueFallback: '-',
      fractionalDigits: 1,
    }),
  }));
}

export function mapBattingSummaryStats(pitcherStats, unitSystem) {
  return pitcherStats.map(item => ({
    PitchType: item.pitchType,
    PitchTypeLabel: Providers.EnumValueProvider.getValue(item.pitchType, GenericPitchType, 'UI').name,
    NumberOfPitches: item.NumberOfPitches,
    // ...getSummaryStatsItemByEnum(item, Enums.BattingStats.HardHitRate, unitSystem, { fractionalDigits: 1 }), // BBP-1161 - hide for now
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.ExitSpeedAvg, unitSystem, { fractionalDigits: 1 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.ExitSpeedMax, unitSystem, { fractionalDigits: 1 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.HitLaunchVAvg, unitSystem, { fractionalDigits: 1 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.CarryMax, unitSystem, { fractionalDigits: 1 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.KZoneRate, unitSystem, { fractionalDigits: 1 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.Hits, unitSystem, { fractionalDigits: 0 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.TotalBases, unitSystem, { fractionalDigits: 0 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.Strikeouts, unitSystem, { fractionalDigits: 0 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.Strikeouts, unitSystem, { fractionalDigits: 0 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.StrikeoutRate, unitSystem, { fractionalDigits: 1 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.Swings, unitSystem, { fractionalDigits: 0 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.Whiffs, unitSystem, { fractionalDigits: 0 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.SwingRate, unitSystem, { fractionalDigits: 1 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.WhiffRate, unitSystem, { fractionalDigits: 1 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.ZSwingRate, unitSystem, { fractionalDigits: 1 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.ZContactRate, unitSystem, { fractionalDigits: 1 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.OSwingRate, unitSystem, { fractionalDigits: 1 }),
    ...getSummaryStatsItemByEnum(item, Enums.BattingStats.OContactRate, unitSystem, { fractionalDigits: 1 }),
  }));
}

export function mapEnumsToDatatableHeaderItems(enumDefItems) {
  if (!Array.isArray(enumDefItems)) {
    return [];
  }

  return enumDefItems.map(enumDef => ({
    text: enumDef.short,
    title: enumDef.description,
    key: enumDef.key,
    value: `${enumDef.key}.value`,
    aggregator: enumDef.aggregator,
  }))
}
