import Vue from 'vue';
import upperCase from 'lodash/upperCase';
import { Client } from '@/services';
import STATES from '../../actions/states';
import { Services, SessionType, M_SET_API_ALERT } from '../../actions/api';
import {
  SESSIONS_REQUEST, SESSIONS_ERROR, SESSIONS_SUCCESS,
  SET_FILTER, SET_SEASON, SET_DATES, SET_PLACE, SET_TEAM,
  SET_OPPONENTS, CLEAR_SESSION_LIST, ERASE_SESSIONS, SET_PAGE,
  SET_ATTENDANTS,
} from '../../actions/sessions';
import games from './modules/games';
import pitching from './modules/pitching';
import hitting from './modules/hitting';
import practice from './modules/practice';
import LiveBP from './modules/LiveBP';
import { getTeamShortCode } from './tools/helpers';
import { ELineupType } from '@/enums/dto';

const GETTERS = {
  datesSorted: state => module => [...state[module].filter.dates].sort(),
  filterIsLoading: state => module => state[module].filter.status === STATES.LOADING,
};

const ACTIONS = {
  [SESSIONS_REQUEST]: ({
    state, commit, dispatch, getters, rootState, rootGetters
  }, module) => new Promise((resolve, reject) => {
    if ([STATES.LOADING, STATES.UNAUTHORIZED].find(k => k === state[module].filter.status)) {
      reject(state[module].filter.status);
      return;
    }
    commit(SESSIONS_REQUEST, module);

    let params = {
      SessionType: SessionType[module.toUpperCase()],
      StartIndex: state[module].filter.offset,
      Count: state.maxRecords,
    };

    // BaseballLeagueService | GetSessions | BaseballSessionManagement | GetManagedLeagueSessionList;
    // BaseballReportService | GetSessions | BaseballSessionManagement | GetSessionList;
    // BaseballLeagueService | GetPracticeSessions | BaseballSessionManagement | GetSessionList;

    // <Token>?</Token>
    // <UserID>?</UserID>
    // <StartIndex>?</StartIndex>
    // <Count>?</Count>
    // <Search>?</Search>
    // <SessionType>?</SessionType>
    // <StartDate>?</StartDate>
    // <EndDate>?</EndDate>
    // <LeagueID>?</LeagueID>
    // <SeasonID>?</SeasonID>

    params.service = Services.BaseballSessionManagement.key;

    if (Array.isArray(getters.currentProfile.managedLeagueIDs) && getters.currentProfile.managedLeagueIDs.includes(rootState.app.filters.season.leagueID)) {
      params.method = Services.BaseballSessionManagement.methods.GetManagedLeagueSessionList.key;
    } else {
      params.method = Services.BaseballSessionManagement.methods.GetSessionList.key;
    }

    switch (module) {
      case 'games':
      case 'practice':
      case 'LiveBP':
        if (!getters.isLeagueManagerForCurrentSeason && state[module].filter.place) {
          params.GamePlace = state[module].filter.place;
        }

        if (getters.isLeagueManagerForCurrentSeason) {
          if (state[module].filter.team_home) {
            params.HomeTeamID = state[module].filter.team_home;
          }
          if (state[module].filter.team_away) {
            params.AwayTeamID = state[module].filter.team_away;
          }
          if (state[module].filter.team_show_all) {
            params.StrictPlaceMatching = !state[module].filter.team_show_all;
          }
        } else if (state[module].filter.opponents) {
          if (params?.GamePlace) {
            if (params.GamePlace === 'home') {
              params.AwayTeamID = state[module].filter.opponents;
            } else if (params.GamePlace === 'away') {
              params.HomeTeamID = state[module].filter.opponents;
            }
            params.StrictPlaceMatching = true;
          } else {
            params.StrictPlaceMatching = false;
            params.HomeTeamID = state[module].filter.opponents;
          }
        }
        break;

      case 'pitching':
      case 'hitting':
        // TODO: what session type?
        // 1	PitchingSession
        // 2	BattingSession
        // 3	PoptimeSession
        // 4	MevoPitching
        // 5	MevoHitting
        // 6	Game
        // 7	GamePractice
        // 8	Unknown
        // params.SessionType = SessionType.PRACTICE;

        // if (state[module].filter.team) {
        //   params.TeamID = state[module].filter.team;
        // }
        // if (state[module].filter.attendants.length) {
        //   support only tags for now
        //   params.PlayerTagIDs = JSON.stringify(state[module].filter.attendants.map(
        //     item => ({
        //       TagID: item,
        //     }),
        //   ));
        // }
        break;
      default:
        return;
    }

    if (rootState.app.filters.season) {
      params.SeasonID = rootState.app.filters.season.id;
    }

    if (state[module]?.filter?.dates?.length) {
      let [start, end] = getters.datesSorted(module);
      if (start) {
        params.StartDate = start;
        if (!end) {
          end = start;
        }
      }
      if (end) {
        params.EndDate = end;
      }
    }

    const axiosPromise = Client({ params });

    axiosPromise.then(
      (resp) => {
        Vue.$log.debug(SESSIONS_REQUEST, resp);
        commit(SESSIONS_SUCCESS, { module, data: resp.data });
        resolve();
      },
      resp => reject(resp),
    ).catch((err) => {
      // commit(M_SET_API_ALERT, {
      //   type: 'error',
      //   message: err.message,
      // });
      reject(err.message);
    });
  }),

  [SET_SEASON]: ({ commit }, { module, value }) => {
    const offset = 0;
    commit(SET_FILTER, { module, key: 'offset', value: offset });
    commit(SET_FILTER, { module, key: 'season', value });
    commit(SET_FILTER, { module, key: 'dates', value: [] });
    // return dispatch(SESSIONS_REQUEST, module);
  },

  [SET_DATES]: ({ commit }, { module, value }) => {
    const offset = 0;
    commit(SET_FILTER, { module, key: 'offset', value: offset });
    commit(SET_FILTER, { module, key: 'dates', value });
    // return dispatch(SESSIONS_REQUEST, module);
  },

  [SET_PLACE]: ({ commit }, { module, value }) => {
    const offset = 0;
    commit(SET_FILTER, { module, key: 'offset', value: offset });
    commit(SET_FILTER, { module, key: 'place', value });
    // return dispatch(SESSIONS_REQUEST, module);
  },

  [SET_TEAM]: ({ commit }, { module, key, value }) => {
    const offset = 0;
    commit(SET_FILTER, { module, key: 'offset', value: offset });
    commit(SET_FILTER, { module, key: `team${key}`, value });
    // return dispatch(SESSIONS_REQUEST, module);
  },

  [SET_OPPONENTS]: ({ commit }, { module, value }) => {
    const offset = 0;
    commit(SET_FILTER, { module, key: 'offset', value: offset });
    commit(SET_FILTER, { module, key: 'opponents', value });
  },

  [SET_ATTENDANTS]: ({ commit }, { module, value }) => {
    const offset = 0;
    commit(SET_FILTER, { module, key: 'offset', value: offset });
    commit(SET_FILTER, { module, key: 'attendants', value });
  },

  [SET_PAGE]: ({ commit, state }, { module, pageNum }) => {
    const offset = (pageNum - 1) * state.maxRecords;
    commit(SET_FILTER, { module, key: 'offset', value: offset });
  },

  [CLEAR_SESSION_LIST]: ({ commit }, module) => {
    commit(ERASE_SESSIONS, module);
  },
};

const MUTATIONS = {
  [SESSIONS_REQUEST]: (state, module) => {
    state[module].filter.status = STATES.LOADING;
  },
  [SESSIONS_SUCCESS]: (state, { module, data }) => {
    Vue.$log.debug(SESSIONS_SUCCESS, module, data);
    // map HomeTeamID, HomeTeamDisplayName, HomeTeamLogo -> HomeTeam{} to fit into single column header
    // map AwayTeamID, AwayTeamDisplayName, AwayTeamLogo -> AwayTeam{} to fit into single column header
    const mapped = data.sessions.map(
      (record) => {
        let awayTeam = record.lineupTeamsOrganizationCodes.find((team) => team.teamType === ELineupType.AWAY);
        let homeTeam = record.lineupTeamsOrganizationCodes.find((team) => team.teamType === ELineupType.HOME || team.teamType === ELineupType.PRACTICE);

        return {
          HomeTeamID: homeTeam?.teamID,
          HomeTeam: {
            Id: homeTeam?.teamID,
            Name: homeTeam?.teamDisplayName,
            ShortCode: getTeamShortCode(homeTeam?.teamDisplayName),
            Url: homeTeam?.logo,
          },
          AwayTeamID: awayTeam?.teamID,
          AwayTeam: {
            Id: awayTeam?.teamID,
            Name: awayTeam?.teamDisplayName,
            ShortCode: getTeamShortCode(awayTeam?.teamDisplayName),
            Url: awayTeam?.logo,
          },
          ...record,
        };
      },
    );
    state[module].list = mapped;
    state[module].total = Math.trunc(data.pagination.totalRows);
    state[module].filter.status = STATES.SUCCESS;
  },
  [SESSIONS_ERROR]: (state, module) => {
    state[module].filter.status = STATES.ERROR;
  },
  [SET_FILTER]: (state, { module, key, value }) => {
    Vue.$log.debug(SET_FILTER, module, key, value);
    Vue.set(state[module].filter, key, value);
    // state[module].filter[key] = value;
  },
  [ERASE_SESSIONS]: (state, module) => {
    if (typeof module !== 'undefined') {
      state[module].list = [];
      state[module].filter.status = STATES.EMPTY;
    }
  },
};

export default {
  modules: {
    games, practice, pitching, hitting, LiveBP,
  },
  state() {
    return {
      maxRecords: 25,
    };
  },
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
};
