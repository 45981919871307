import { Model } from '@vuex-orm/core';
import playerNameFilter from '@/filters/playerNameFilter';
import PlayerPosition from '../../enums/PlayerPosition';

export class ConnectedTag extends Model {
  static entity = 'connectedTags';

  static primaryKey = ['userID', 'playerTagID'];

  static state() {
    return {
      fetching: false,
      fetched: false,
    };
  }

  static fields() {
    return {
      userID: this.number(null),
      playerTagID: this.number(null),
      status: this.string(''),
      createDate: this.string(''),
      ts: this.string(''),
      Tag: this.attr(null),
      RosterPlayers: this.attr([]),
    };
  }

  get FirstName() {
    return this.Tag?.parameters?.FirstName ?? '';
  }

  get LastName() {
    return this.Tag?.parameters?.LastName ?? '';
  }

  get DisplayName() {
    return this.Tag?.parameters?.DisplayName ?? '';
  }

  get BirthDate() {
    return this.Tag?.parameters?.BirthDate ?? null;
  }

  get Height() {
    return this.Tag?.parameters?.Height || null;
  }

  get Weight() {
    return this.Tag?.parameters?.Weight || null;
  }

  get AvatarURL() {
    return this.Tag?.parameters?.AvatarURL ?? undefined;
  }

  get AvatarBase64() {
    return this.Tag?.parameters?.AvatarBase64 ?? '';
  }

  get Gender() {
    return this.Tag?.parameters?.Gender ?? null;
  }

  get Address() {
    return this.Tag?.parameters?.Address ?? null;
  }

  get School() {
    return this.Tag?.parameters?.School ?? null;
  }

  get GraduationYear() {
    return this.Tag?.parameters?.GraduationYear ?? null;
  }

  get Email() {
    return this.Tag?.parameters?.Email ?? null;
  }

  /**
   * Parameters set via DTO
   * @see https://bitbucket.org/flightscope/dto-schema/src/40de023beb9e7cc40a7527ce5954b99b2ce1a023/baseball/entity/playertag-parameter.json?at=feature/baseball
   *
   */
  get BatHandedness() {
    return this.Tag?.parameters?.BatHandedness || 'U';
  }

  get ThrowHandedness() {
    return this.Tag?.parameters?.ThrowHandedness || 'U';
  }

  // unify with PlayerModel
  get Bats() {
    return this.BatHandedness;
  }

  get Throws() {
    return this.ThrowHandedness;
  }

  get PrimaryPosition() {
    let tagPosition;

    if (!this.hasRosterPlayers || !this.recentRosterPlayer || !Array.isArray(this.recentRosterPlayer?.position) || this.recentRosterPlayer.position.length < 1) {
      if (this.Tag?.parameters?.Position) {
        if (Array.isArray(this.Tag.parameters.Position)) {
          tagPosition = this.Tag.parameters.Position[0];
        } else {
          tagPosition = this.Tag.parameters.Position;
        }
      }
    }

    return tagPosition || this.recentRosterPlayer.position[0] || PlayerPosition.Unidentified.key;
  }

  get SecondaryPosition() {
    let tagSecondaryPosition;
    if (this.Tag?.parameters?.Position && Array.isArray(this.Tag?.parameters?.Position)) {
      tagSecondaryPosition = this.Tag.parameters.Position[1];
    }

    if (!this.hasRosterPlayers || !this.recentRosterPlayer || !Array.isArray(this.recentRosterPlayer?.position) || this.recentRosterPlayer.position.length < 2) {
      return tagSecondaryPosition || 'UI';
    }

    return tagSecondaryPosition || this.recentRosterPlayer.position[1] || 'UI';
  }

  get Number() {
    const number = this.Tag?.parameters?.Number;
    if (!this.hasRosterPlayers || !this.recentRosterPlayer || !this.recentRosterPlayer.number || this.recentRosterPlayer.number === '-') {
      return number || '';
    }

    return number || this.recentRosterPlayer.number || '';
  }

  /*
  Custom accessors
  */
  // needed for FormattedName
  get JerseyNumber() { return this.Number; }

  get FormattedName() {
    return playerNameFilter(process.env.VUE_APP_PLAYER_NAME_FORMAT, this).trim();
  }

  get hasRosterPlayers() {
    return this.RosterPlayers.length > 0;
  }

  get recentRosterPlayer() {
    return this.RosterPlayers[0];
  }

  /**
   * @see playerNameFilter
   */
  get FormattedNameWithJersey() {
    return playerNameFilter(process.env.VUE_APP_PLAYER_NAME_FORMAT, this, this.Number).trim();
  }
}

export default ConnectedTag;
