import Vue from 'vue';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import { Client } from '@/services';
import { Player, Team } from '@/models/orm/Hierarchy';
import { checkIfNilOrFiniteOrNotNumber, defNumber } from '@/filters/number_render';
import { Services } from '../store/actions/api';
import RankingStatsMapper from '../mappers/rankingStatMapper';
import { captureException } from '../plugins/sentry';

const RequestMap = new Map();

export default {
  find(id) {
    return Player.find(id);
  },

  async getByName(name, exists, leagueId) {
    let players;

    if (!name || typeof (name) !== 'string') {
      throw new TypeError('Invalid argument');
    }

    let trimmed = name.trim();

    let possibleId = +trimmed;

    await Player.dispatch('setFetching', true);

    try {
      const params = {
        service: Services.TeamManagement.key,
        method: Services.TeamManagement.methods.FindPlayers.key,
        ...Services.TeamManagement.methods.FindPlayers.query,
      };
      if (defNumber(possibleId)) {
        params.NameOrId = possibleId;
      } else {
        params.NameOrId = `${trimmed}%`;
      }

      params.LeagueID = leagueId;

      const response = await Client({ params });

      const { tags } = response.data;

      const filtered = tags.reduce((acc, p) => {
        if (p?.id) {
          let team = get(p, ['RosterPlayers', 0, 'team']); // RosterPlayers[0].teamID
          acc.push({ team, ...p });
        }

        return acc;
      }, []);

      let inserted;

      if (filtered.length) {
        inserted = await Player.insertOrUpdate({
          data: filtered,
          insertOrUpdate: ['team'],
        });
      }

      const ids = uniq(filtered.map(p => p.id));

      players = await Player.query()
        .whereIdIn(ids)
        .where((record) => !exists || record.exists === true)
        .with(['team', 'connectedTag'])
        .all();

    } catch (error) {
      captureException(error);
      Vue.$log.debug(error);
      players = [];
    } finally {
      await Player.dispatch('setFetching', false);
    }

    return players;
  },

  async getForTeam(teamID, exists) {
    let players;

    if (!teamID || checkIfNilOrFiniteOrNotNumber(teamID)) {
      throw new TypeError('Invalid argument');
    }

    const team = Team.find(teamID);

    if (team?.fetchedPlayers) {
      players = Player.query()
        .where('teamID', teamID)
        .where((record) => !exists || record.exists === true)
        .with(['team', 'connectedTag'])
        .all();
    } else {
      Player.dispatch('setFetching', true);

      try {
        const params = {
          service: Services.TeamManagement.key,
          method: Services.TeamManagement.methods.GetBaseballRosterPlayers.key,
          ...Services.TeamManagement.methods.GetBaseballRosterPlayers.query,
        };
        params.TeamID = teamID;
        params.ActiveOnly = false;
        params.IncludeDeleted = true;

        const response = await Client({ params });

        const data = response.data.tags.map((p) => ({ teamID, ...p }));

        await Player.insertOrUpdate({ data });
        await Player.dispatch('setFetched', true);
        await Team.update({ where: teamID, data: { fetchedPlayers: true } });

        players = Player.query()
          .where('teamID', teamID)
          .where((record) => !exists || record.exists === true)
          .with(['team', 'connectedTag'])
          .all();
      } catch (error) {
        captureException(error);
        Vue.$log.debug(error);
      } finally {
        Player.dispatch('setFetching', false);
      }
    }

    return players;
  },

  getDefault(id) {
    let player = new Player();

    player.id = id;

    player.isDefault = true;

    player.teamID = 'unknown';

    player.parameters = {
      FirstName: 'unknown',
      LastName: id,
      DisplayName: `unknown #${id}`,
      Position: 'UI',
    };

    return player;
  },

  async fetchRankingStats(tagId) {
    try {
      let params = {
        service: Services.BaseballSessionManagement.key,
        method: Services.BaseballSessionManagement.methods.GetCumulativeStatsForScope.key,
        ...Services.BaseballSessionManagement.methods.GetCumulativeStatsForScope.query,
      };

      params.TagID = tagId;
      params.GroupBy = 'seasonId';
      params.SessionType = 'game';
      params.Context = '';
      params.DataVerified = true;

      const battingStats = Client({ params: {...params, Context: 'batting'} });
      const pitchingStats = Client({ params: {...params, Context: 'pitching'} });
      const generalStats = Client({ params: {...params, Context: 'general'} });

      const output = {
        batting: (await battingStats).data,
        pitching: (await pitchingStats).data,
        general: (await generalStats).data,
      };

      // map the data to correspond to Entities.RankingStat class
      const mapper = new RankingStatsMapper();

      for (const key in output) {
        if (!Object.hasOwnProperty.call(output, key)) {
          continue;
        }

        output[key] = output[key].map(p => {
          p.parameters = mapper.mapArray(p.parameters || []);
          return p;
        });
      }

      return output;
    } catch(err) {
      Vue.$log.debug(err);
      captureException(err);
      return {
        batting: [],
        pitching: [],
        general: [],
      };
    }
  },

  async fetchPlayer(id) {
    if (!id || typeof id !== 'number') {
      throw TypeError(`Player ID needs to be an positive integer. ${id} was used.`);
    }

    try {
      let params = {
        service: Services.TeamManagement.key,
        method: Services.TeamManagement.methods.GetPlayerTag.key,
        ...Services.TeamManagement.methods.GetPlayerTag.query,
      };

      params.TagID = id;
      const { data: fromApi } = await Client({ params });

      const injected = await Player.insertOrUpdate({
        data: [fromApi],
      });

      const fromOrm = this.find(id);

      return fromOrm;
    } catch (error) {
      captureException(error);
      Vue.$log.debug(error);
      Vue.$log.debug('Error when retrieving player ', id);
      return this.getDefault(id);
    }
  },

  saveRequest(id) {
    if (!id || typeof id !== 'number') {
      throw TypeError(`Player ID needs to be an positive integer. ${id} was used.`);
    }
    // save request and return
    RequestMap.set(id, this.fetchPlayer(id));

    return RequestMap.get(id);
  },

  async get(id) {
    if (!id || typeof id !== 'number') {
      throw TypeError(`Player ID needs to be an positive integer. ${id} was used.`);
    }
    const player = this.find(id);

    // get from ORM
    if (player) {
      if (RequestMap.has(id)) {
        RequestMap.delete(id);
      }
      return player;
    }

    if (RequestMap.has(id)) {
      // get existing request
      return RequestMap.get(id);
    }

    return this.saveRequest(id);
  },
};
