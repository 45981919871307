/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} property
 * @returns
 */
export default function dynamicSort(property, isString) {
  let sortOrder = 1;

  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }

  return function (a,b) {
      if (isString) {
        if(sortOrder === -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }
      } else if (sortOrder === -1) {
        return b[property] - a[property];
      } else {
        return a[property] - b[property];
      }
  }
}
