import { Enums, Classificators } from '@flightscope/baseball-stats';

let GenericPitchType;
let GenericPitchTypeGroup;
let GenericStrikeZone;

if (process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Baseball.key) {
  ({ BaseballPitchType: GenericPitchType, BaseballPitchTypeGroup: GenericPitchTypeGroup } = Enums);
  ({ UniversalStrikeZoneMLB: GenericStrikeZone } = Classificators);
} else if (process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Softball.key) {
  ({ SoftballPitchType: GenericPitchType, SoftballPitchTypeGroup: GenericPitchTypeGroup } = Enums);
  ({ UniversalStrikeZoneFastPitch: GenericStrikeZone } = Classificators);
} else {
  ({ PitchType: GenericPitchType, PitchTypeGroup: GenericPitchTypeGroup } = Enums);
  GenericStrikeZone = () => {
    throw new Error('Using a not supported sport type, are we?');
  };
}

export { GenericPitchType, GenericPitchTypeGroup, GenericStrikeZone };
