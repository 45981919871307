import { Store } from '@/services';

const has = (module) => {
  return Store.hasModule(module);
};

const ormHas = (model) => {
  return Store.$db().model(model);
};

export default {
  has,
  ormHas,
};
