import moment, { HTML5_FMT } from 'moment';

export const DATE_FMT = {
  YEAR: 'YYYY',
  DATE: 'YYYY-MM-DD',
  DATETIME_SECONDS: 'YYYY-MM-DD HH:mm:ss',
};

const determineFormat = (format) => {
  switch (format) {
    case 'dateTime':
      return DATE_FMT.DATETIME_SECONDS;
    case 'date':
      return HTML5_FMT.DATE;
    case 'time':
      return HTML5_FMT.TIME;
    default:
      return format;
  }
};

export function utcToLocal(date, format) {
  if (!date) return '';

  let native = false;

  switch(typeof format) {
    case 'undefined':
      format = 'dateTime';
      break;
    case 'object':
      native= true;
      break;
    default:
      // NOOP
  }
  const mdate = moment.utc(date);
  const local = mdate.local();

  if(native) {
    return new Date(local).toLocaleString(undefined, format);
  }

  return local.format(determineFormat(format));
}

export function formatUtc(date, format) {
  if (!date) return '';
  format = (typeof format !== 'undefined') ? format : 'dateTime';
  const mdate = moment.utc(date);
  return mdate.format(determineFormat(format));
}
