import Vue from 'vue';
import { Client } from '@/services';
import { Player, Team, ConnectedTag } from '@/models/orm/Hierarchy';
import { checkIfNilOrFiniteOrNotNumber } from '@/filters/number_render';
import { jsonToFormData } from '@/utils/http-common';
import { Services } from '../store/actions/api';
import { captureException } from '@sentry/vue';

export default {
  async listFollowedPlayers(force) {
    const params = {
      service: Services.TeamManagement.key,
      method: Services.TeamManagement.methods.ListConnectedTags.key,
      ...Services.TeamManagement.methods.ListConnectedTags.query,
    };

    let store = ConnectedTag.store();
    let user = store.getters.currentProfile;

    let tags = ConnectedTag.query().where('userID', user.ID).get();

    if (!force && tags.length) {
      return tags;
    }

    ConnectedTag.dispatch('setFetching', true);

    params.Count = 1000;
    params.StartIndex = 0;

    try {
      const { data } = await Client({ params });

      const { ConnectedTags, pagination } = data;

      if (force) {
        ConnectedTag.deleteAll();
      }

      await ConnectedTag.insertOrUpdate({ data: ConnectedTags });

      tags = ConnectedTag.query().where('userID', user.ID).get();

      ConnectedTag.dispatch('setFetched', true);
    } catch (error) {
      Vue.$log.debug(error);
      captureException(error);
    } finally {
      ConnectedTag.dispatch('setFetching', false);
    }

    return tags;
  },

  /**
   *
   * @param {Player} player
   */
  async followPlayer(player) {
    if (!player || !(player instanceof Player)) {
      throw new TypeError('Invalid type of player. Should be a Player');
    }

    const params = {
      service: Services.TeamManagement.key,
      method: Services.TeamManagement.methods.SendFollowIntent.key,
    };

    const payload = Services.TeamManagement.methods.SendFollowIntent.params;

    payload.TeamID = player.teamID;
    payload.TagID = player.id;

    const data = jsonToFormData(payload);

    try {
      const response = await Client({ data, params, method: 'post' });
      Vue.$log.debug(response);
      return true;
    } catch (error) {
      Vue.$log.debug(error);
      captureException(error);
      return false;
    }
  },

  /**
   *
   * @param {Number} tagId
   */
  async delete(tagId) {
    if (!tagId || checkIfNilOrFiniteOrNotNumber(tagId)) {
      throw new TypeError('Invalid type of tagId. Should be a Number');
    }

    const params = {
      service: Services.FlightScopeUser.key,
      method: Services.FlightScopeUser.methods.DeleteConnectedTag.key,
    };

    const payload = Services.FlightScopeUser.methods.DeleteConnectedTag.params;

    payload.TagID = tagId;

    const data = jsonToFormData(payload);

    try {
      const { data: resp } = await Client({ data, params, method: 'post' });

      if (resp) {
        return true;
      }
      return false;
    } catch (error) {
      Vue.$log.debug(error);
      captureException(error);
      return false;
    }
  },
};
