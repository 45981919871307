import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '../store';

// import scrollBehavior from './scroll';

Vue.use(VueRouter);

export function pushRouteContext(val) {
  this.$router.push({ params: { context: val } });
}

export const router = new VueRouter({
  // scrollBehavior,
  mode: 'history',
});

const guard = async (to, from, next) => {
  const store = Store;

  const authenticated = await store.dispatch('CHECK_AUTHENTICATION', { source: 'router' });

  if (store.getters.isAuthorized && store.hasModule('stripeElements')) {
    await store.dispatch('stripeElements/redirects', { to, from, next });
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthorized) {
      next({
        path: '/',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
      store.dispatch('app/loaded', null, { root: true });
    }
  } else if (to.matched.some((record) => record.meta.guestOnly)) {
    if (store.getters.isAuthorized) {
      next({
        path: process.env.VUE_APP_DEFAULT_REDIRECT,
      });
    } else {
      next();
      store.dispatch('app/loaded', null, { root: true });
    }
  } else next();
  store.dispatch('app/loaded', null, { root: true });
};

router.beforeEach((to, from, next) => {
  Vue.$log.debug('Route Traversal', 'From:', from.name, ' => ', to.name);
  next();
});

router.beforeEach(guard);

export default router;
