import { Model } from '@vuex-orm/core';
import { Player } from '@/models/orm/Hierarchy';
import PlayerPosition from '@/enums/PlayerPosition'

// https://bitbucket.org/flightscope/dto-schema/src/b0a7e7f7c0a3e307e750a4bd2cac8da0706f8109/baseball/entity/lineup-tag.json?at=feature/baseball
export class LineupTag extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'lineupTags';

  static primaryKey = ['sessionLineUpID', 'playerIndex'];

  static state() {
    return {
      fetching: false,
    };
  }

  static fields() {
    return {
      sessionLineUpID: this.number(null),
      tagID: this.number(null),
      playerIndex: this.number(null),
      position: this.string(''),
      replacedFielderId: this.number(null).nullable(),
      replacedFielderPosition: this.string('').nullable(),
      height: this.number(null).nullable(),

      player: this.belongsTo(Player, 'tagID'),
    };
  }

  // eslint-disable-next-line class-methods-use-this
  get main() {
    return true;
  }

  get id() {
    return this.tagID;
  }

  get isDesignatedHitter() {
    return this.position === PlayerPosition.DesignatedHitter.key;
  }

  get isPitcher() {
    return this.position === PlayerPosition.Pitcher.key;
  }

  get PrimaryPosition() {
    if (this.position && this.position !== PlayerPosition.Unidentified.key) {
      return this.position;
    }
    if (this.player) {
      return this.player.PrimaryPosition;
    }
    return this.position;
  }

  get AvatarURL() {
    return this?.player?.AvatarURL ||'';
  }

  get FormattedName() {
    return this?.player?.FormattedName || `Lineup #${this.id}`;
  }
}

export default LineupTag;
