function jerseyNumberStr(jerseyNumber) {
  return jerseyNumber ? ` (#${jerseyNumber})` : '';
}

function displayNameStr(FirstName, LastName) {
  const firstName = (typeof FirstName === 'string' && FirstName.trim() !== '') ? FirstName : '';
  const lastName = (typeof LastName === 'string' && LastName.trim() !== '') ? LastName : '';
  return [lastName, firstName].filter(txt => txt).join(' ');
}

function displayNamePrecedence({ DisplayName, FirstName, LastName }, jerseyNumber) {
  const displayName = (DisplayName && typeof DisplayName === 'string' && DisplayName.trim() !== '-') ?
    DisplayName : displayNameStr(FirstName, LastName);

  return `${displayName}${jerseyNumberStr(jerseyNumber)}`;
}

function lastFirstDisplayName({ DisplayName, FirstName, LastName }, jerseyNumber) {
  const displayName = (DisplayName && typeof DisplayName === 'string' && DisplayName.trim() !== '-') ?
    DisplayName : displayNameStr(FirstName, LastName);

  const displayNameSuffix = (displayName.trim() !== '' && displayName !== '-') ? ` ${displayName}` : '';
  const firstNameSuffix = (FirstName.trim() !== '') ? ` ${FirstName}` : '';
  return `${LastName}${firstNameSuffix}${displayNameSuffix}${jerseyNumberStr(jerseyNumber)}`;
}

/**
 *
 * @param {Player|ConnectedTag} param0
 * @param {Number} jerseyNumber
 * @returns
 */
function displayOrFirstLast({ DisplayName, FirstName, LastName }, jerseyNumber) {
  const displayName = (
    DisplayName && typeof DisplayName === 'string' && DisplayName.trim() !== '-'
  ) ? DisplayName : displayNameStr(LastName, FirstName);
  const jn = jerseyNumber ? jerseyNumberStr(jerseyNumber) : '';
  return [displayName, jn].filter(txt => txt).join(' ');
}

/**
 * @filterName predefined set: displayNamePrecedence, lastFirstDisplayName, displayOrFirstLast
 * @sessionPlayer mapped roster tag having fields FirstName, LastName, DisplayName
 */
function playerNameFilter(filterName, sessionPlayer, jerseyNumber) {
  switch (filterName) {
    case 'displayNamePrecedence':
      return displayNamePrecedence(sessionPlayer, jerseyNumber);
    case 'lastFirstDisplayName':
      return lastFirstDisplayName(sessionPlayer, jerseyNumber);
    case 'displayOrFirstLast':
      return displayOrFirstLast(sessionPlayer, jerseyNumber);
    default:
      return lastFirstDisplayName(sessionPlayer, jerseyNumber);
  }
}

export default playerNameFilter;
