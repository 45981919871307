// import Vue from 'vue';
import { SESSIONS_FILTER_CLEAN } from '../../../actions/sessions';

export default {
  state() {
    return {
      filter: {
        status: '',
        season: new Date().getFullYear(),
        place: '',
        dates: [],
        offset: 0,
        team_home: '',
        team_away: '',
        team_show_all: false,
        opponents: ''
      },
      list: [],
      total: 0,
    };
  },
  getters: {
    // datesSorted: state => [...state.filter.dates].sort(),
  },
  actions: {
    [SESSIONS_FILTER_CLEAN]: ({ commit }) => {
      commit(SESSIONS_FILTER_CLEAN);
    },
  },
  mutations: {
    [SESSIONS_FILTER_CLEAN]: (state) => {
      state.list = [];
      state.total = 0;
      state.filter = {
        status: '',
        season: new Date().getFullYear(),
        place: '',
        dates: [],
        offset: 0,
        team_home: '',
        team_away: '',
        team_show_all: false,
        opponents: ''
      };
    },
  },
};
