import Vue from 'vue';
import { sleep } from '../../utils/helpers';
import { Services, FAKE_API_REQUEST } from '../actions/api';
import { captureException } from '@sentry/vue';

const actions = {
  async [FAKE_API_REQUEST](store, config) {
    const {
      localError = false, coreHandler, extendedHandler, ...axiosConfig
    } = config;

    let data;

    try {
      switch (axiosConfig.params.method) {
        case Services.TeamManagement.methods.ListLeague.key:
          await sleep(5000 * Math.random());

          data = () => import('../../../tests/data/ListLeague.response.json');

          return {
            data: await data(),
          };
        default:
          throw new TypeError('Not implemented mock response');
      }
    } catch (error) {
      captureException(error);
      Vue.$log.debug('Mocked error:', error);
      return error;
    }
  },
};

export default {
  actions,
};
