import { Model } from '@vuex-orm/core';

export class Roster extends Model {
  static entity = 'rosters';

  static primaryKey = 'team_id';

  static state() {
    return {
      fetching: false,
    };
  }

  static fields() {
    return {
      team_id: this.number(null),
      tags: this.attr(null),
    };
  }
}

export default Roster;
