import isNil from 'lodash/isNil';
import isFinite from 'lodash/isFinite';
import isNumber from 'lodash/isNumber';

const checkIfNilOrFiniteOrNotNumber = (value) => isNil(value) || !isFinite(value) || !isNumber(value);

const defNumber = (value, def = 0) => (checkIfNilOrFiniteOrNotNumber(value) ? def : value);

const numberOrHyphen = (value) => defNumber(value, '-');

const sumTotalFromArray = (statsArray) => {
  return statsArray.reduce((total, num) => (checkIfNilOrFiniteOrNotNumber(num) ? total : total + num), null);
};

export { defNumber, numberOrHyphen, checkIfNilOrFiniteOrNotNumber, sumTotalFromArray };
