import { Enums, Providers, Selectors } from '@flightscope/baseball-stats'
import { captureException } from '@sentry/vue';


const mapEnumValToOption = ({ key, name }) => ({ text: name, value: key });

const mapEnumToOptionsList = (enumObj) => {
  return  Object.values(enumObj).map(mapEnumValToOption);
};

export const EPitchParameters = [
  Enums.PitchData.Speed,
  Enums.PitchData.Spin,
  Enums.PitchData.SpinDirection,
  Enums.PitchData.Extension,
  Enums.PitchData.ReleasePointX,
  Enums.PitchData.ReleasePointZ,
  Enums.PitchData.LaunchV,
  Enums.PitchData.LaunchH,
  Enums.PitchData.BreakV,
  Enums.PitchData.BreakVInd,
  Enums.PitchData.BreakH,
  Enums.PitchData.PFXX,
  Enums.PitchData.PFXZ,
  Enums.PitchData.ApproachSpeed,
  Enums.PitchData.ApproachV,
  Enums.PitchData.ApproachH,
];

export const EHitParameters = [
  Enums.HitData.ExitSpeed,
  Enums.HitData.CarryDistance,
  Enums.HitData.LaunchV,
  Enums.HitData.LaunchH,
  Enums.HitData.LandingPointX,
  Enums.HitData.LandingPointY,
];

export const EHandedness = [
  { text: 'All', value: '' },
  { text: Enums.Handedness.Left.name, value: Enums.Handedness.Left.key },
  { text: Enums.Handedness.Right.name, value: Enums.Handedness.Right.key },
  { text: Enums.Handedness.Switch.name, value: Enums.Handedness.Switch.key },
];

export const EHandednessExtended = [
  ...EHandedness,
  { text: 'Switch', value: Enums.Handedness.Switch.key },
];

export const EPitchSet = [
  { text: 'All', value: '' },
  mapEnumValToOption(Enums.PitchMechanics.WindUp),
  mapEnumValToOption(Enums.PitchMechanics.Stretch),
  mapEnumValToOption(Enums.PitchMechanics.Unidentified),
];

export const EPitchResult = mapEnumToOptionsList(Enums.PitchResult);

const PitchCallExtTag = new Selectors.PitchCallEnumSelector().select(Enums.ScoringType.extendedTagging.key)

export const EPitchCallExtTag = mapEnumToOptionsList(PitchCallExtTag);

const PlateAppearanceResultExtTag = new Selectors.PlateAppearanceResultEnumSelector().select(Enums.ScoringType.extendedTagging.key)
export const EPlateAppearanceResultExtTag = mapEnumToOptionsList(PlateAppearanceResultExtTag);

export const ERunnersOn = [{ text: 'All', value: '' }, ...mapEnumToOptionsList(Enums.RunnersOn)];

export const EPOuts = [
  //{ text: 'All', value: '' },
  { text: 'No outs', value: 0 },
  { text: 'One', value: 1 },
  { text: 'Two', value: 2 },
];

export const EPitchParameterKeys = EPitchParameters.map(item => item.key);

export const EHitParameterKeys = EHitParameters.map(item => item.key);

export const enumerate = (key, enumDefinition, property, defaultKey, fallback) => {
  try {
    return Providers.EnumValueProvider.getValue(
      key, enumDefinition, defaultKey, false, property || 'name'
    );
  } catch (error) {
    // todo - stringify enum
    captureException(error);
    console.log(`Unable to get enum value for key ${key}`);
    return fallback;
  }
};
