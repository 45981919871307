import { Model } from '@vuex-orm/core';
import { League } from './Hierarchy';

export class Organization extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'organizations';

  static state() {
    return {
      fetching: false,
    };
  }

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.number(null),
      code: this.string(''),
      displayName: this.string(''),
      leagues: this.hasMany(League, 'organizationID'),
    };
  }
}

export default Organization;
