import Vue from 'vue';
import { Client } from '@/services';
import { checkIfNilOrFiniteOrNotNumber } from '@/filters/number_render';
import { Services } from '../store/actions/api';
import { jsonToFormData } from '@/utils/http-common';
import { captureException } from '@sentry/vue';

export default {
  async deleteMedia(MediaID) {
    if (!MediaID || checkIfNilOrFiniteOrNotNumber(MediaID)) {
      throw new TypeError('Invalid argument');
    }

    let response;

    try {
      const params = {
        service: Services.BaseballSessionManagement.key,
        method: Services.BaseballSessionManagement.methods.DeleteMedia.key,
      };

      let payload = Services.FlightScopeMedia.methods.DeleteMedia.params;
      payload.MediaID = MediaID;

      const data = jsonToFormData(payload);

      response = await Client({
        params,
        method: 'post',
        data,
      });

      return { message: `Video was deleted` };

    } catch (error) {
      captureException(error);
      Vue.$log.debug(error);
      Vue.$log.debug(`Error when deleting media `, MediaID);
    }

    return { error: `Error when deleting media ${MediaID}` };
  },
};
