import { Model } from '@vuex-orm/core';

export class FormattedResult extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'formattedResults';

  static primaryKey = 'ResultID';

  static state() {
    return {
      fetching: false,
    };
  }

  static fields() {
    return {
      AtBatIndex: this.number(null).nullable(),
      BatID: this.number(null).nullable(),
      CreateDate: this.string(null),
      Data: this.attr(null).nullable(),
      HitResultID: this.number(null).nullable(),
      IsInvalid: this.boolean(false),
      Media: this.attr(null).nullable(),
      PitchResultID: this.number(null).nullable(),
      PlayerID: this.number(null).nullable(),
      RelatedBatterPlayerID: this.number(null).nullable(),
      RelatedBatterTagID: this.number(null).nullable(),
      ResultID: this.number(null),
      ResultType: this.string(''),
      SessionID: this.number(null),
      TagID: this.number(null),
    };
  }
}

export default FormattedResult;
