const PlayerPosition = {
  Pitcher: { key: 'P', name: 'Pitcher' },
  Catcher: { key: 'C', name: 'Catcher' },
  FirstBase: { key: '1B', name: 'First Baseman' },
  SecondBase: { key: '2B', name: 'Second Baseman' },
  ThirdBase: { key: '3B', name: 'Third Baseman' },
  ShortStop: { key: 'SS', name: 'Shortstop' },
  LeftField: { key: 'LF', name: 'Left Fielder' },
  CenterField: { key: 'CF', name: 'Center Fielder' },
  RightField: { key: 'RF', name: 'Right Fielder' },
  Infielder: { key: 'IF', name: 'Infielder' },
  Outfielder: { key: 'OF', name: 'Outfielder' },
  DesignatedHitter: { key: 'DH', name: 'Designated Hitter' },
  UtilityPlayer: { key: 'UT', name: 'Utility Player' },
  Unidentified: { key: 'UI', name: 'Unidentified' },
};

export default PlayerPosition;
