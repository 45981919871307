import { Model } from '@vuex-orm/core';
import { Season, Organization } from './Hierarchy';

export class League extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'leagues';

  static state() {
    return {
      fetching: false,
    };
  }

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.number(null),
      organizationID: this.number(null),
      sport: this.string(''),
      displayName: this.string(''),
      organization: this.belongsTo(Organization, 'organizationID'),
      seasons: this.hasMany(Season, 'leagueID'),
    };
  }
}

export default League;
