/**
 * This enum contains lookup table of all available columns (merged for different modes / session schema / apps)
 */
const DataTableColumnIndexes = {
  No: 1,
  Video: 2,

  PitchTime: 3,
  PitchUTCTime: 4,
  PitchDate: 5,
  PitchUTCDate: 6,
  PitchPitcher: 7,
  PitchPitcherID: 8,
  PitchPitcherIDType: 9,
  PitchHandedness: 10,
  PitchPitcherTeam: 11,

  HitBatter: 12,
  HitBatterID: 13,
  HitBatterIDType: 14,
  HitSwingHandedness: 15,
  HitBatterTeam: 16,

  PitchMechanics: 17,
  PitchClassification: 18,
  PitchCall: 19,
  PlateAppearanceResult: 20,
  PitchResult: 21,
  PitchSpeed: 22,
  PitchLaunchV: 23,
  PitchLaunchH: 24,
  PitchSpin: 25,
  PitchSpinDirection: 26,
  PitchSpinTiltR: 27,
  PitchReleaseHeightR: 28,
  // inverted pitch release side, BBP-1121
  PitchReleaseSideInvR: 29,
  PitchExtension: 30,
  PitchMlbBreakV: 31,
  PitchMlbBreakVInd: 32,
  PitchMlbBreakH: 33,
  PitchHomePlateHeightR: 34,
  PitchHomePlateSideR: 35,
  PitchApproachSpeed: 36,
  PitchApproachV: 37,
  PitchApproachH: 38,
  PitchFlightTime: 39,

  HitExitSpeed: 40,
  HitLaunchV: 41,
  HitLaunchH: 42,
  HitSpin: 43,
  HitCarryDistance: 44,
  HitTrackDistance: 45,
  HitFlightTime: 46,

  PitchPFXX: 47,
  PitchPFXZ: 48,
  PitchX0: 49,
  PitchY0: 50,
  PitchZ0: 51,
  PitchVX0: 52,
  PitchVY0: 53,
  PitchVZ0: 54,
  PitchAX: 55,
  PitchAY: 56,
  PitchAZ: 57,
  PitchGuid: 58,
  PitchPX: 59,
  PitchPZ: 60,
  PitchStrikeZoneFront: 61,
  PitchStrikeZoneTop: 62,
  PitchStrikeZoneBottom: 63,
  PitchMlbSpinTiltHours: 64,
  PitchMlbSpinTiltMinutes: 65,

  HitHitPoint: 66,
  HitLandingPoint: 67,

  PitchPolyX: 68,
  PitchPolyY: 69,
  PitchPolyZ: 70,

  HitPolyX: 71,
  HitPolyY: 72,
  HitPolyZ: 73,

  PitchStrikeZonePositionR: 74,
  PitchCountR: 75,
  PitchPlateAppearanceR: 76,

  HitBattedBallTypeR: 77,
  HitLandingSideR: 78,
  HitLandingDistanceR: 79,

  ScoringBeforePlayInning: 80,
  ScoringBeforePlayInningIsTop: 81,
  ScoringBeforePlayRunnersOn: 82,
  ScoringBeforePlayBalls: 83,
  ScoringBeforePlayStrikes: 84,
  ScoringBeforePlayOuts: 85,
  ScoringAfterPlayRuns: 86,
  ScoringAfterPlayErrors: 87,

  // new columns added due to PS BBP-1048
  PitchPitcherExternalId: 88,
  PitchPitcherLeagueId: 89,

  HitBatterExternalId: 90,
  HitBatterLeagueId: 91,

  // replaced by inverted pitch release side, BBP-1121
  PitchReleaseSideR: 92,
};

export default DataTableColumnIndexes;
