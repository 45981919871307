
import Vue from 'vue';
import VueLogger from 'vuejs-logger';

import { IS_PRODUCTION } from '../constants';

Vue.use(VueLogger, {
  isEnabled: true,
  logLevel: IS_PRODUCTION ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
});
