export default {
  contact: {
    formHeading: 'Contact Us',
    formSubheading: 'Please fill out your information for more details about FS Cloud services.',
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    emailLabel: 'Email',
    companyLabel: 'Company',
    messageLabel: 'Your Message',
    submitForm: 'Contact Us',
    phoneLabel: 'Phone',

    successMsg: 'Form was sent succesfully.',
    errorMsg: 'There were some errors during form submission.',

    message_required: 'Message is required',
  },
};
