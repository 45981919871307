import Vue from 'vue';
import Vuetify, {
  VAlert,
  VAutocomplete,
  VAvatar,
  VBtn,
  VBtnToggle,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VCheckbox,
  VCol,
  VContainer,
  VDataFooter,
  VDataIterator,
  VDataTable,
  VDataTableHeader,
  VDatePicker,
  VDialog,
  VDivider,
  VImg,
  VInput,
  VItem,
  VItemGroup,
  VLabel,
  VListItem,
  VResponsive,
  VRow,
  VSelect,
  VSimpleTable,
  VSkeletonLoader,
  VTabs,
  VTextField,
  VToolbar,
  VTooltip,
} from 'vuetify/lib';
import {
  Ripple
} from 'vuetify/lib/directives';

import icons from './icons';
import lang from './lang';

Vue.use(Vuetify, {
  components: {
    VAlert,
    VAutocomplete,
    VAvatar,
    VBtn,
    VBtnToggle,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VCheckbox,
    VCol,
    VContainer,
    VDataFooter,
    VDataIterator,
    VDataTable,
    VDataTableHeader,
    VDatePicker,
    VDialog,
    VDivider,
    VImg,
    VInput,
    VItem,
    VItemGroup,
    VLabel,
    VListItem,
    VResponsive,
    VRow,
    VSelect,
    VSimpleTable,
    VSkeletonLoader,
    VTabs,
    VTextField,
    VToolbar,
    VTooltip,
  },
  directives: {
    Ripple,
  },
});

const vuetify = new Vuetify({
  lang,
  icons,
  theme: {
    options: {
      customProperties: true,
      cspNonce: process.env.VUE_APP_NONCE,
    },
    themes: {
      light: {
        primary: '#d6001c',
        link: '#1867C0',
        mevo: '#d6001c',
        game_result_lost: '#ffcdd2',
        game_result_won: '#b2dfdb',
        game_result_tied: '#8fcbeb',
        header_bg: '#181717',
        breaking: '#41670A',
        changeup: '#00569D',
        fastballs: '#BE2E00',
        ungrouped: '#575757',
        'silver-chalice': '#9e9e9e',
        'bitter-lemon': '#cfe008',
        tundora: '#4a4545',
        danube: '#6a9cd3',
        'mine-shaft': '#222',
        pear: '#e2e543',
        fsmevo: '#d6001c',
        landinggray: '#f7f7f7',
        thunder: '#231f20',
        'dark-gray': '#8e8e93',
        'light-gray': '#fafafa',
        'mineshaft2': '#242424',
      },
    },
  },
});

export default vuetify;
