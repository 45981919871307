import { Formatters } from '@flightscope/baseball-stats';
import { GenericStrikeZone } from '@/enums/Generic';

const sessionResultsFormatter = (results) => {
  if (!(results instanceof Array)) {
    return false;
  }

  const formatter = new Formatters.ResultsFormatters.SessionResultsStructureFormatter();

  let strikeZoneModel = new GenericStrikeZone();

  return Formatters.IncompatibleSessionResultsFormatter(formatter, results, strikeZoneModel);
};

export default sessionResultsFormatter;
