import groupBy from 'lodash/groupBy';
import findLast from 'lodash/findLast';
import findLastIndex from 'lodash/findLastIndex';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { BenchTag, LineupTag, PlayerSubstitution, PositionSwitch, Team } from '@/models/orm/Hierarchy';
import { Model } from '@vuex-orm/core';
import PlayerPosition from '@/enums/PlayerPosition';
import dynamicSort from '@/utils/sorters/dynamicSort';

// https://bitbucket.org/flightscope/dto-schema/src/b0a7e7f7c0a3e307e750a4bd2cac8da0706f8109/baseball/entity/session-lineup-v2.json?at=feature/baseball
export class Lineup extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'lineups';

  static state() {
    return {
      fetching: false,
    };
  }

  static fields() {
    return {
      id: this.number(null),
      sessionID: this.number(null),
      type: this.string(''),
      teamID: this.number(null),
      createDate: this.string(''),

      ts: this.string(''),
      rosterID: this.number(null),

      battingOrder: this.hasMany(LineupTag, 'sessionLineUpID'),
      substitutions: this.hasMany(PlayerSubstitution, 'lineup_id'),
      switches: this.hasMany(PositionSwitch, 'lineup_id'),
      bench: this.hasMany(BenchTag, 'lineup_id'),

      team: this.belongsTo(Team, 'teamID'),
    };
  }

  get battersFromOrder() {
    return this.battingOrder.filter((i) => i.position !== PlayerPosition.Pitcher.key);
  }

  get pitchersFromOrder() {
    return this.battingOrder.filter((i) => i.position === PlayerPosition.Pitcher.key);
  }

  get groupedSwitches() {
    return groupBy(this.switches, 'fielderATagID');
  }

  get groupedSubstitutions() {
    return groupBy(this.substitutions, 'replacedTagID');
  }

  /** @returns {(PositionSwitch|PlayerSubstitution)[]} */
  get events() {
    return [...this.switches, ...this.substitutions].sort((a, b) => {
      let compare = a.date.localeCompare(b.date);

      if (compare === 0) {
          let aIsSub = a instanceof PlayerSubstitution;
          let aIsSw = a instanceof PositionSwitch;
          let bIsSub = b instanceof PlayerSubstitution;
          let bIsSw = b instanceof PositionSwitch;

          if ((aIsSub && bIsSub) || (aIsSw && bIsSw)) {
            return compare;
          }
          if (aIsSub && bIsSw) return -1;
          return 1;
      }
      return compare;
    });
  }

  /**
   *
   * @param {Number} id
   * @param {(LineupTag)[]} inOrder
   * @param {boolean} onlyLineup
   * @param {boolean} last
   * @returns {LineupTag|BenchTag|null}
   */
  findPlayer(id, inOrder, onlyLineup, last) {
    let order = this.battingOrder;
    if (inOrder) {
      order = inOrder;
    }

    let findFn = find;

    if (last) {
      findFn = findLast;
    }

    let player = findFn(order, (p) => {
      return (p.tagID === id);
    });

    if (!player && !onlyLineup) {
      player = findFn(this.bench, (p) => p.tagID === id);
    }

    return player;
  }

  get battingList() {
    let events = [...this.events];

    let battingOrder = [...this.battingOrder].sort(dynamicSort('playerIndex'));

    for (let i = 0; i < events.length; i++) {
      let event = events[i];

      if (event instanceof PlayerSubstitution) {
        let { enteredDate, type, enteredTagID, replacedTagID } = event;

        let exitingPlayer = this.findPlayer(replacedTagID);
        let enteringPlayer = this.findPlayer(enteredTagID);

        if (enteringPlayer) {

          let battingIndex = battingOrder.findIndex((element, index, arr) => {
            return element.tagID === exitingPlayer.tagID;
          });

          if (battingIndex !== -1) {
            battingOrder.splice(battingIndex + 1, 0, {
              tagID: enteringPlayer.tagID,
              enteredDate,
              type,
              exitingPlayer,
              enteringPlayer,
            });
          }
        }
      }

      if (event instanceof PositionSwitch) {
        // FIXME: needs to handle not referenced object inside switch
        let fielderA = this.findPlayer(event.fielderATagID, battingOrder, true, true);
        let fielderB = this.findPlayer(event.fielderBTagID, battingOrder, true, true);

        if (fielderA && fielderB) {
          let newPos;
          if (fielderB instanceof LineupTag || fielderB instanceof BenchTag) {
            newPos = fielderB.PrimaryPosition;
          } else {
            newPos = fielderB.enteringPlayer.PrimaryPosition;
          }

          if (fielderA instanceof LineupTag || fielderA instanceof BenchTag) {
            fielderA.switch =
              [newPos];
          } else {
            fielderA.enteringPlayer.switch =
              [newPos];
          }
        }
      }
    }

    return battingOrder;
  }

  get pitchingList() {
    let pitchers = this.battingOrder.sort(dynamicSort('playerIndex')).filter((item) => {
      return item.isPitcher || item.isDesignatedHitter;
    });

    pitchers = pitchers.map((item) => {
      if (item.isPitcher) {
        return item;
      }
      if (item.isDesignatedHitter && item.replacedFielderId) {
        let player = this.findPlayer(item.replacedFielderId);

        if (player)  {
          return player;
        }
      }

      return null;
    }).filter(p => p);

    return pitchers;
  }

  get playerIds() {
    let order = this.battingOrder;

    let list = [
      ...order.map(p => p.id),
    ];

    let bench = this.bench;
    list = [
      ...list,
      ...bench.map(p => p.tagID),
    ];

    return list;
  }
}

export default Lineup;
