import Vue from 'vue';
import { measurementSystem } from '@flightscope/units-converter';
import { Client } from '@/services';
import Repository from '@/repositories/RepositoryFactory';
import { User as UserModel, Team } from '@/models/orm/Hierarchy';
import { Services } from '../actions/api';
import {
  USER_REQUEST, USER_ERROR, USER_SUCCESS, USER_METRICS, USER_ROLES, USER_CLEAN,
  SET_USER_CONFIG, USER_TEAMS, USER_LEAGUE_REQUEST, USER_ABILITIES_UPDATE, USER_IS_MEDIA_UPLOAD_ALLOWED
} from '../actions/user';
import { AUTH_LOGOUT } from '../actions/auth';
import STATES from '../actions/states';
import dataTableColumnsProvider from '../../providers/DataTableColumnsProvider';
import { ability, defineAbilitiesFor, subject, PUBLIC_ABILITIES } from '../../services/ability';
import { captureException, setUser } from '@sentry/vue';

const TeamRepo = Repository.get('teams');

export const UNIT_SYSTEMS = [
  { system: measurementSystem.METRIC, abbr: 'met', label: 'Metric Units' },
  { system: measurementSystem.IMPERIAL, abbr: 'imp', label: 'Imperial Units' },
];

export const defaultSystem = process.env.VUE_APP_CONFIG_UNITS === measurementSystem.IMPERIAL ? 1 : 0;

const mapProfileTeam = team => ({
  TeamID: parseInt(team.ID, 10),
  TeamDisplayName: team.DisplayName,
  Photo: team.Photo,
  Logo: team.Logo,
  IsPublic: (team.IsPublic == 1),
  TS: team.TS,
  CreateDate: team.CreateDate,
  // TODO fix on WS that returns empty objects
  Location: (typeof (team.Location) !== 'string') ? '' : team.Location,
  Latitude: team.Latitude,
  Longitude: team.Longitude,
  ExternalTeamType: team.ExternalTeamType,
  ExternalTeamID: team.ExternalTeamID,
  // TODO receive season from response instead of calculating from CreateDate
  Season: (new Date(team.CreateDate)).getFullYear(),
});

const STATE = {
  status: '',
  profile: {},
  roles: [],
  teams: [],
  selectedUnitsSystem: UNIT_SYSTEMS[defaultSystem],
  config: {
    dataTableColumns: dataTableColumnsProvider.getIndexesOfDefaultColumns(),
  },
  mediaUpload: {},
};

const GETTERS = {
  getProfile: (state, getters) => getters.currentProfile,

  selectedUnitsSystem: (state) => state.selectedUnitsSystem,

  isProfileLoaded: (state, getters) => !!getters.currentProfile?.ID,

  roles: (state, getters) => {
    return getters.isProfileLoaded ? state.roles : [];
  },

  hasRoles: (state, getters) => {
    return getters.isProfileLoaded && getters.roles.length;
  },

  /**
   * Checking old role based schema
   * @param {*} state
   * @param {*} getters
   */
  isLeagueManager: (state, getters) => {
    return (
      getters.hasRoles && getters.roles.filter((role) => role.Code === process.env.VUE_APP_MANAGER_ROLE).length !== 0
    );
  },

  /**
   * Checking new schema season->league management
   * @param {*} state
   * @param {*} getters
   */
  isLeagueManagerFor(state, getters) {
    return (id) => {
      return getters.isProfileLoaded
      && Array.isArray(getters.currentProfile.managedLeagueIDs)
      && getters.currentProfile.managedLeagueIDs.includes(id);
    };
  },

  /**
   * Checks all team related arrays in user profile
   * @param {*} state
   * @param {*} getters
   */
  hasRoleInTeam(state, getters) {
    return (id) => {
      return getters.isProfileLoaded
      && (
        (
          Array.isArray(getters.currentProfile.createdByUser)
          && getters.currentProfile.createdByUser.includes(id)
        )
        || (
          Array.isArray(getters.currentProfile.coachForTeams)
          && getters.currentProfile.coachForTeams.some(team => team.teamID == id)
        )
        || (
          Array.isArray(getters.currentProfile.scorekeeperForTeams)
          && getters.currentProfile.scorekeeperForTeams.includes(id)
        )
        || (
          Array.isArray(getters.currentProfile.accessAsManager)
          && getters.currentProfile.accessAsManager.includes(id)
        )
      );
    };
  },

  /**
   * Wrapper for checking new schema season->league management
   * @param {*} state
   * @param {*} getters
   * @param {*} rootState
   */
  isLeagueManagerForCurrentSeason(state, getters, rootState) {
    return getters.isLeagueManagerFor(rootState.app.filters.season.leagueID);
  },

  isMaintenanceUser: (state, getters) => {
    return (
      getters.isProfileLoaded
      && getters.hasRoles
      && getters.roles.filter((role) => role.Code === 'ws_maintenance').length !== 0
    );
  },
  // TODO state.profile.Player.Teams - when support to full player visitor is enabled
  userTeamIDs: (state) => state.teams.map((team) => team.TeamID),

  isCustom: (state, getters) => (
    getters.isProfileLoaded
    && getters.hasRoles
    && state.roles.filter((role) => role.Code === 'ws_bb_level_custom').length > 0
  ),

  hasSubscribed: (state, getters) => (
    getters.isProfileLoaded
    && getters.hasRoles
    && state.roles.filter((role) => ['ws_bb_level0', 'ws_bb_level1', 'ws_bb_level2'].includes(role.Code)).length > 0
  ),

  currentProfile() {
    return UserModel.query().withAll().first();
  },
};

const actions = {
  [USER_REQUEST]: ({ commit, dispatch, getters }) => new Promise((resolve, reject) => {
    commit(USER_REQUEST);
    Client({
      params: {
        service: Services.BaseballPlayer.key,
        method: Services.BaseballPlayer.methods.GetProfile.key,
      },
    })
      .then((resp) => {
        const { User, Roles, Coach } = resp.data;

        const { Role } = Roles ?? {};

        if (Array.isArray(Role)) {
          User.roles = Role;
          commit(USER_ROLES, User.roles);
        } else if (Role) {
          User.roles = [Role];
          commit(USER_ROLES, User.roles);
        }

        UserModel.insertOrUpdate({ data: [User] });
        setUser({ id: User.ID, username: User.DisplayName });
        commit(USER_SUCCESS, User);

        dispatch(USER_ABILITIES_UPDATE, null, { root: true });

        resolve(getters.isProfileLoaded);
      })
      .catch((error) => {
        commit(USER_ERROR);
        Vue.$log.debug(error);
        reject(error);
      });
  }),

  [USER_METRICS]: ({ commit }, system) => new Promise((resolve) => {
    commit(USER_METRICS, system);
    resolve();
  }),

  [USER_CLEAN]: ({ commit }) => new Promise((resolve) => {
    commit(USER_CLEAN);
    ability.update(PUBLIC_ABILITIES);
    resolve();
  }),

  [SET_USER_CONFIG]({ commit }, payload) {
    if (payload.key === 'dataTableColumns' && payload.value.length) {
      commit(SET_USER_CONFIG, payload);
    }
  },
  [USER_SUCCESS]({ commit }, payload) {
    commit(USER_SUCCESS, payload);
  },
  [USER_ROLES]({ commit }, payload) {
    commit(USER_ROLES, payload);
  },

  async [USER_LEAGUE_REQUEST]({ dispatch, rootGetters }) {
    try {
      const resp = await Client({
        params: {
          service: Services.TeamManagement.key,
          method: Services.TeamManagement.methods.ListLeague.key,
        },
      });

      Vue.$log.debug(resp);

      let {
        leagues, organizations, seasons, managedLeagueIDs
      } = resp.data;

      await dispatch('entities/insertOrUpdate', {
        entity: 'organizations',
        data: organizations,
      });

      await dispatch('entities/insertOrUpdate', {
        entity: 'leagues',
        data: leagues,
      });

      await dispatch('entities/insertOrUpdate', {
        entity: 'seasons',
        data: seasons,
      });

      await dispatch('entities/update', {
        entity: 'users',
        where: rootGetters.currentProfile.ID,
        data: {
          managedLeagueIDs,
        },
      });

      return true;
    } catch (error) {
      captureException(error);
      Vue.$log.debug(error);
      return false;
    }
  },

  [USER_ABILITIES_UPDATE]({ state, getters }) {
    const newRules = defineAbilitiesFor(getters.currentProfile);

    ability.update(newRules.rules);
  },

  async [USER_IS_MEDIA_UPLOAD_ALLOWED] ({ commit }) {
    let allowed = {};
    try {
      const response = await Client({
        params: {
          service: Services.FlightScopeMedia.key,
          method: Services.FlightScopeMedia.methods.IsMediaUploadAllowed.key,
        },
      })
      allowed = response.data;
    } catch (error) {
      captureException(error);
      Vue.$log.debug(error);
    }

    commit(USER_IS_MEDIA_UPLOAD_ALLOWED, allowed);
    return allowed;
  }
};

const mutations = {
  [USER_REQUEST]: (state) => {
    state.status = STATES.LOADING;
  },
  [USER_SUCCESS]: (state, userProfile) => {
    state.status = STATES.SUCCESS;
    state.profile = userProfile;
  },
  [USER_ROLES]: (state, roles) => {
    state.roles = roles;
  },
  [USER_TEAMS]: (state, teams) => {
    state.teams = teams;
  },
  [USER_ERROR]: (state) => {
    state.status = STATES.ERROR;
  },
  [AUTH_LOGOUT]: (state) => {
    state.profile = {};
    state.roles = {};
  },
  [USER_METRICS]: (state, resp) => {
    state.selectedUnitsSystem = resp;
  },
  [USER_CLEAN]: (state) => {
    state.status = STATES.EMPTY;
    state.profile = {};
    state.roles = [];
    state.selectedUnitsSystem = UNIT_SYSTEMS[defaultSystem];
  },
  [SET_USER_CONFIG](state, payload) {
    Vue.set(state.config, payload.key, payload.value);
  },
  [USER_IS_MEDIA_UPLOAD_ALLOWED]: (state, mediaUpload) => {
    state.mediaUpload = mediaUpload;
  }
};


export default {
  state() {
    return STATE;
  },
  getters: GETTERS,
  actions,
  mutations,
};
