export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_ERROR = 'USER_ERROR';
export const USER_METRICS = 'USER_METRICS';
export const USER_ROLES = 'USER_ROLES';
export const USER_CLEAN = 'USER_CLEAN';
export const SET_USER_CONFIG = 'SET_USER_CONFIG';
export const USER_TEAMS = 'USER_TEAMS';
export const USER_LEAGUE_REQUEST = 'USER_LEAGUE_REQUEST';
export const USER_ABILITIES_UPDATE = 'USER_ABILITIES_UPDATE';
export const USER_IS_MEDIA_UPLOAD_ALLOWED  = 'USER_IS_MEDIA_UPLOAD_ALLOWED ';
