import Vue from 'vue';
import isFunction from 'lodash/isFunction';
import { HTTP } from '../../utils/http-common';
import initialCoreErrorHandler from './initialCoreErrorHandler';
import { captureException } from '@sentry/vue';


export async function Client({ coreHandler, errorHandler, ...axiosConfig }) {
  // TODO: change structure, add input validation

  try {
    const response = await HTTP(axiosConfig);
    return response;
  } catch (error) {
    captureException(error);
    Vue.$log.debug('Error interceptor', error);

    if (isFunction(errorHandler) && !errorHandler(error)) {
      return Promise.reject(error);
    }

    const coreErrorHandler = coreHandler && isFunction(coreHandler) ? coreHandler : initialCoreErrorHandler;

    await coreErrorHandler(error);

    return Promise.reject(error);
  }
}

export default Client;
