import moment from 'moment';

class DateTime {
  static past(count, unit) {
    return moment().subtract(count, unit);
  }

  static future(count, unit) {
    return moment().add(count, unit);
  }

  static now() {
    return moment();
  }

  /**
   *
   * @param {moment|Date} [baseOnDate]
   * @returns {number} Unix TS
   */
  static toUnixTs(baseOnDate) {
    if(baseOnDate && moment.isMoment(baseOnDate)) return baseOnDate.unix();
    if(baseOnDate && moment.isDate(baseOnDate)) return moment(baseOnDate).unix();
    return moment().unix();
  }
}

export { DateTime };
