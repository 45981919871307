import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import { Store } from '@/services';
import {
  IS_PRODUCTION, ErrorType, ErrorMessages, AlertType
} from '@/constants';
import { SET_ALERT } from '@/store/actions/alerts';
import { UNAUTHORIZE } from '@/store/actions/auth';

/**
 *
 * @param {Object} error
 */
export default async function initialCoreErrorHandler(error) {
  if (!error) {
    return true;
  }
  const {
    config, request, response, isAxiosError, message
  } = error;

  if (!isAxiosError) {
    return true;
  }

  Vue.$log.debug('Handled error', {
    config,
    request,
    response,
    isAxiosError,
    message,
  });

  let showAlert = false;
  let alertContent = {
    type: AlertType.warning,
    message: '',
    sub: '',
  };

  if (typeof response === 'undefined') {
    // handle network errors
    alertContent.message = vuetify.framework.lang.t('$vuetify.alerts.no_response');
    if (!IS_PRODUCTION) {
      alertContent.sub = message;
    }
    showAlert = true;
  } else {
    // handle http errors
    let { status, data } = response;

    if (!IS_PRODUCTION) {
      alertContent.sub = data;
    }

    if (data.type) {
      switch (data.type) {
        case ErrorType.AUTH_ERROR:
          // 401 Unauthorized
          alertContent.type = AlertType.error;
          alertContent.message = vuetify.framework.lang.t('$vuetify.alerts.forbidden_access');
          if (data.message !== ErrorMessages.NOT_VERIFIED) {
            showAlert = true;
            Store.dispatch(UNAUTHORIZE);
          }
          break;

        case ErrorType.ACCESS_ERROR:
          // 403 Forbidden
          alertContent.message = vuetify.framework.lang.t('$vuetify.alerts.forbidden_access');
          showAlert = true;
          break;

        case ErrorType.SERVICE_INVALID:
        case ErrorType.METHOD_INVALID:
          // 501 Not Implemented
          showAlert = true;
          alertContent.message = vuetify.framework.lang.t('$vuetify.alerts.request_error');
          Vue.$log.error('Accessing something that does not exist?');
          break;

        case ErrorType.HTTP_ERROR:
        case ErrorType.INTERNAL_ERROR:
        case ErrorType.SERVICE_ERROR:
        case ErrorType.SOAP_ERROR:
        case ErrorType.EMPTY_REQUEST:
          showAlert = true;
          alertContent.message = vuetify.framework.lang.t('$vuetify.alerts.request_error');
          break;

        // case ErrorType.SERVICE_ERROR:
        // case ErrorType.WS_ERROR:
        //   // 400 Bad Request
        //   if (data.message) {
        //     const regex = /(?<message>[\w]+)([:#\s]+(?<code>\w)+)?/gm;
        //     const match = regex.exec(data.message);
        //     if (match.length > 1 && match.groups.message) {
        //       if (match.groups.message === ErrorMessage.INVALID_LOGIN_OR_PASSWORD) {
        //         alertContent.message = vuetify.framework.lang.t('$vuetify.alerts.invalid_credentials');
        //         alertContent.type = AlertType.error;
        //         showAlert = true;
        //       }
        //       if (match.groups.message === ErrorMessage.CLIENT_TEMPORARY_SUSPENDED) {
        //         alertContent.message = 'Please reload your application or try to connect later.';
        //         alertContent.type = AlertType.error;
        //         showAlert = true;
        //       }
        //       // TODO: display other errors when not in production
        //     }
        //   }
        //   break;
        default:
          Vue.$log.error('something went wrong here', error);
        // 500 Internal Server Error
        // alertContent.message = vuetify.framework.lang.t('$vuetify.alerts.no_response');
        // alertContent.type = AlertType.error;
        // showAlert = true;
      }
    }
  }

  if (showAlert) {
    Store.dispatch(`${SET_ALERT}`, alertContent, { root: true });
  }
  return false;
}
