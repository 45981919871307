import coerce from 'semver/functions/coerce';
import gte from 'semver/functions/gte';
import { Providers, Entities, Enums } from '@flightscope/baseball-stats';
import { ResultType } from '@flightscope/baseball-stats/src/enums';
import {
  SESSION_MAYHAVE_APPROACH_PARAMS,
  SESSION_MAYHAVE_UTCDATE_PARAM,
  SESSION_MAYHAVE_SCORING_PARAMS,
} from '@/enums/featureFlags/session';

import { SessionType } from '@/store/actions/api';
import { Model } from '@vuex-orm/core';
import sessionResultsFormatter from '@/formatters/sessionResultsFormatter';
import FilterStores from '@/enums/StoreKeys';

export class SessionV1 extends Model {
  static entity = 'sessionsv1';

  static primaryKey = 'SessionID';

  static state() {
    return {
      fetching: false,
    };
  }

  static fields() {
    return {
      AwayTeamCreatorID: this.number(null),
      AwayTeamDisplayName: this.string(''),
      AwayTeamID: this.number(null),
      AwayTeamLogo: this.string('').nullable(),
      Bats: this.string('').nullable(),
      DeletedResults: this.attr([]).nullable(),
      DeviceID: this.string('').nullable(),
      DisplayName: this.string(''),
      EventType: this.string(''),
      HomeTeamCreatorID: this.number(null),
      HomeTeamDisplayName: this.string(''),
      HomeTeamID: this.number(null),
      HomeTeamLogo: this.string('').nullable(),
      InvalidResults: this.attr(null),
      Media: this.attr(null),
      MediaForSession: this.attr([]).nullable(),
      Parameters: this.attr(null),
      PlayersForSession: this.attr([]).nullable(),
      Results: this.attr([]).nullable(),
      Score: this.attr(null),
      Season: this.number(''),
      SessionCreateDate: this.string(''),
      SessionCreatorID: this.number(null),
      SessionEndDate: this.string(''),
      SessionID: this.number(null),
      SessionStatus: this.string(''),
      SessionType: this.string(''),
      TournamentID: this.string(''),
      UserWithAwayTeam: this.boolean(false),
      UserWithHomeTeam: this.boolean(false),

      results: this.attr(null).nullable(),
      filters: this.attr(null).nullable(),

      // localCache
      ttlExpire: this.number(null).nullable(),
    };
  }

  static beforeCreate(model) {
    let formatted = sessionResultsFormatter(model.Results);
    model.results = formatted;
    model.filters = Object.values(FilterStores).reduce((ac, a) => ({ ...ac, [a]: {} }), {});
  }

  get invalidResultsIds() {
    let { InvalidResults } = this;
    if (InvalidResults) {
      return InvalidResults.map(({ ResultID }) => ResultID);
    }
    return [];
  }

  get hasInvalidData() {
    return this.invalidResultsIds.length;
  }

  get resultsCount() {
    return this.results?.length ?? 0;
  }

  get resultsWithInvalidFlag() {
    return this.results.filter((o) => o.IsInvalid).length;
  }

  get invalidResults() {
    if (this.InvalidResults.length) {
      let { InvalidResults } = this;
      return InvalidResults;
    }
    return [];
  }

  get features() {
    let feats = {
      [SESSION_MAYHAVE_APPROACH_PARAMS]: false,
      [SESSION_MAYHAVE_UTCDATE_PARAM]: false,
      [SESSION_MAYHAVE_SCORING_PARAMS]: false,
    };
    if (gte(coerce(this.schema), '1.5.0')) {
      feats[SESSION_MAYHAVE_APPROACH_PARAMS] = true;
    }
    if (gte(coerce(this.schema), '1.6.0')) {
      feats[SESSION_MAYHAVE_UTCDATE_PARAM] = true;
    }
    if (gte(coerce(this.schema), '1.7.0')) {
      feats[SESSION_MAYHAVE_SCORING_PARAMS] = true;
    }
    return feats;
  }

  get id() {
    return this.SessionID ? this.SessionID : 0;
  }

  get schema() {
    return this?.Parameters?.SessionSchema ?? '0';
  }

  get type() {
    return this?.SessionType || '_default_';
  }

  get hasAwayTeam() {
    return this.type === SessionType.GAMES || this.type === SessionType.PRACTICE ? this.AwayTeamID > 0 : null;
  }

  get hasLineScore() {
    return this.Score !== null;
  }

  mightHaveFeature(feat) {
    return this.features.hasOwnProperty(feat) && this.features[feat];
  }

  // eslint-disable-next-line class-methods-use-this
  get mightHaveTagging() {
    return true;
  }

  get mightHaveScoring() {
    return this.type === SessionType.GAMES && this.mightHaveFeature(SESSION_MAYHAVE_SCORING_PARAMS);
  }

  get hasNoPitchingData() {
    return this.type === SessionType.HITTING;
  }

  get hasNoHittingData() {
    return this.type === SessionType.PITCHING;
  }

  get MatchingResult() {
    return (ResultID) => {
      return this.Results.find((r) => r[Enums.ResultData.Id.key] === ResultID) || null;
    };
  }

  get batters() {
    const people = Providers.PlayerListProvider.getListOfBatters(this.results);
    return people;
  }

  get pitchers() {
    const people = Providers.PlayerListProvider.getListOfPitchers(this.results);
    return people;
  }

  get mediaObj() {
    return (mediaId) => {
      if (this.Media.hasOwnProperty(mediaId)) {
        return this.Media[mediaId];
      }
      return { MediaType: 'nonvideo' };
    }
  }

  getPitchForMatchingResult(ResultID) {
    let result = this.MatchingResult(ResultID);

    if (!result) {
      return null;
    }

    if (SessionV1.isHitType(result)) {
      const pitchId = result[Enums.ResultData.PitchId.key];
      if (!pitchId) {
        return null;
      }
      return Entities.BaseballResultsProcessor.getRelatedResult(pitchId, this.Results) || null;
    }

    return result;
  }

  getHitForMatchingResult(ResultID) {
    let result = this.MatchingResult(ResultID);

    if (!result) {
      return null;
    }

    if (SessionV1.isHitType(result)) {
      return result;
    }

    const hitId = result[Enums.ResultData.HitId.key];
    if (!hitId) {
      return null;
    }

    return Entities.BaseballResultsProcessor.getRelatedResult(hitId, this.Results) || null;
  }

  static isHitType(matchingResult) {
    if (!matchingResult) {
      return false;
    }

    return matchingResult.ResultType && matchingResult.ResultType === ResultType.Hit.key;
  }
}

export default SessionV1;
