import moment from 'moment';
import { marked } from 'marked';
import { StringTests } from '../constants';

const concatAndDeDuplicate = (...arrs) => [...new Set([].concat(...arrs))];

function hasProp(object, prop) {
  return Object.prototype.hasOwnProperty.call(object, prop);
}

function matrixToArray(str) {
  // eslint-disable-next-line no-useless-escape
  let match = str.match(StringTests.css_matrix);
  return match;
}

function getFirstElByClass(el, className) {
  const elements = el.getElementsByClassName(className);
  if (elements.length) {
    return elements[0];
  }
  return false;
}

function selectOptionsFromEnums(enumsObj) {
  return Object.values(enumsObj)
    .map(({ key, name }) => ({ text: name, value: key }));
}

function sanitizeGuid(_guid) {
  if (!_guid || typeof _guid !== 'string') return null;
  let allowed = StringTests.guid_allowed;
  let guid = _guid.replace(allowed, '');
  if (guid) {
    return `result-${guid}`;
  }
  return null;
}

function seasons() {
  const currentYear = moment().year();
  const years = [];

  for (let i = currentYear; i >= 2019; i--) {
    years.push(i);
  }
  return years;
}

function mapRunnersOnSelectToProvider(value) {
  return value === null || value === undefined || value === '' ? undefined : [value];
}

const dropdownIcon = (val) => (val ? 'mdi-menu-up': 'mdi-menu-down');

async function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

function scrollToElement(hash) {
  const element = document.getElementById(hash);
  if (element) {
    element.scrollIntoView({behavior: "smooth"});
  }
}

/**
 *
 * @param {string} markdown
 * @return
 */
function markdownToHtml(markdown) {
  return marked.parse(markdown);
}

function copyToClipboard(text) {
  if (typeof navigator.clipboard === 'undefined') {
    this.$nextTick(() => {
      const input = document.createElement('textarea');
      input.classList.add('copyboard-textarea');
      input.value = text;
      document.body.appendChild(input);
      input.focus();
      input.select();
      const result = document.execCommand('copy');
      input.remove();
    });
  } else {
    navigator.clipboard.writeText(text);
  }
}

export {
  concatAndDeDuplicate,
  hasProp,
  matrixToArray,
  getFirstElByClass,
  selectOptionsFromEnums,
  sanitizeGuid,
  seasons,
  mapRunnersOnSelectToProvider,
  dropdownIcon,
  sleep,
  scrollToElement,
  copyToClipboard,
  markdownToHtml,
};
