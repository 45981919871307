export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const AlertType = {
  error: 'error',
  warning: 'warning',
  info: 'info',
  success: 'success',
};

export const ErrorType = {
  INPUT_INVALID: 'INPUT_INVALID',
  SERVICE_INVALID: 'SERVICE_INVALID',
  METHOD_INVALID: 'METHOD_INVALID',
  EMPTY_REQUEST: 'EMPTY_REQUEST',
  HTTP_ERROR: 'HTTP_ERROR',
  SOAP_ERROR: 'SOAP_ERROR',
  WS_ERROR: 'WS_ERROR',
  AUTH_ERROR: 'AUTH_ERROR',
  ACCESS_ERROR: 'ACCESS_ERROR',
  CONTENT_ERROR: 'CONTENT_ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  SERVICE_ERROR: 'SERVICE_ERROR',
  NETWORK_ERROR: 'NETWORK_ERROR',
};

export const ErrorMessages = {
  NOT_VERIFIED: 'Authentication failed - user is not verified',
};

export const WsErrorType = {
  RECORD_EXIST: 'RECORD_ALREADY_EXIST',
  PERMISSION_DENIED: 'PERMISSION_DENIED'
};

export const ResponseType = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const ErrorMessage = {
  INVALID_LOGIN_OR_PASSWORD: 'INVALID_LOGIN_OR_PASSWORD',
  CLIENT_TEMPORARY_SUSPENDED: 'ERROR_CLIENT_TEMPORARY_SUSPENDED',
  INVALID_ARGUMENTS: 'INVALID_ARGUMENTS'
};

export const StringTests = {
  email: /^[^\s]+@[^\s]+\.[^\s]+$/,
  email2: /^.+@.+\..+$/,
  email3: /^((?:[A-Za-z0-9!#$%&'*+\-\/=?^_`{|}~]|(?=[\s\S]*?"$)[\s\S]|\.(?=[\s\S]*?"\.[\s\S]*?$))[\s\S]){1,64}(@)((?:[A-Za-z0-9.\-])*(?:[A-Za-z0-9])\.(?:[A-Za-z0-9]){2,})$/,
  // see https://regex101.com/r/qmrmav/1
  email4: /^([A-Za-z0-9!#$%&'*+\-\/=?^_`{|}~]{1,}\.?)*[A-Za-z0-9!#$%&'*+\-\/=?^_`{|}~](@)((?:[A-Za-z0-9.\-])*(?:[A-Za-z0-9])\.(?:[A-Za-z0-9]){2,})$/,
  css_matrix: /(-?[0-9\.]+)/g,
  guid_allowed: /(?![\w\d_\-.]+)./g,
};
