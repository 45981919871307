import {
  getPreConfigured, measurementSystem, conversionType, quantity, conversionResult,
} from '@flightscope/units-converter';

const unitsConverter = getPreConfigured(measurementSystem.SI, measurementSystem.METRIC);
const unitsConverterIToM = getPreConfigured(measurementSystem.IMPERIAL, measurementSystem.METRIC);

Object.freeze(unitsConverter);
Object.freeze(unitsConverterIToM);


/**
  *
  * @param {*} value
  * @param {*} type
  * @param {*} targetSystem
  */
function convertValue(value, type, targetSystem) {
  return unitsConverter.convertType(value, type, targetSystem);
}

export default unitsConverter;
export {
  unitsConverter,
  unitsConverterIToM,
  measurementSystem,
  conversionType,
  quantity,
  conversionResult,
  convertValue,
};
