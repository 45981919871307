import { Model } from '@vuex-orm/core';
import { League, Role, ConnectedTag } from './Hierarchy';

export class User extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'users';

  static primaryKey = 'ID';

  static state() {
    return {
      fetching: false,
    };
  }

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      AvatarURL: this.string('').nullable(),
      DisplayName: this.string(''),
      Email: this.string(''),
      Firstname: this.string('').nullable(),
      GenderID: this.number(null),
      Handedness: this.string(''),
      ID: this.number(null),
      IsDeleted: this.number(null),
      IsPublic: this.number(null),
      Lastname: this.string('').nullable(),
      Parameters: this.attr(null).nullable(),
      TS: this.string(''),
      managedLeagueIDs: this.attr(null).nullable(),

      createdByUser: this.attr(null).nullable(),
      coachForTeams: this.attr(null).nullable(),
      scorekeeperForTeams: this.attr(null).nullable(),
      accessAsManager: this.attr(null).nullable(),
      connectedRosterTags: this.attr(null).nullable(),

      connectedTags: this.hasMany(ConnectedTag, 'userID'),
      managedLeagues: this.hasManyBy(League, 'managedLeagueIDs'),
      roles: this.hasMany(Role, 'user_id'),
    };
  }

  role(code){
    return this.roles.find(role => role.Code === code);
  }

  hasRole(code) {
    return !!this.role(code);
  }

  get isCustom() {
    return this.hasRole('ws_bb_level_custom');
  }

  get isTrial() {
    return this.hasRole('ws_trial_period');
  }

  get trialEnd() {
    if (this.isTrial) {
      return this.role('ws_trial_period').Expires;
    }
    return null;
  }

  get Avatar() {
    return this.AvatarURL || process.env.VUE_APP_DEFAULT_PLAYER_AVATAR;
  }
}

export default User;
