import Vue from 'vue';
import { make } from 'vuex-pathify';

import STATES from '../actions/states';

const STATE = {
  status: '',
  layout: {
    drawer: false,
  },
  filters: {
    season: null,
  },
};

const getters = {
  ...make.getters(STATE),
  isLoading: state => state.status === STATES.LOADING,
  isLoaded: state => state.status === STATES.SUCCESS,
};

const actions = {
  load({ commit }) {
    commit('SET_STATUS', STATES.LOADING);
  },

  loaded({ commit }) {
    commit('SET_STATUS', STATES.SUCCESS);
  },

  'setFilters.season': ({ commit, rootGetters }, payload) => {
    commit('SET_FILTERS.SEASON', payload);
  },

  'setLayout.drawer': ({ commit, rootGetters }, payload) => {
    commit('SET_LAYOUT.DRAWER', payload);
  },
};

// automatically generate mutations
const mutations = {
  ...make.mutations(STATE),

  'SET_FILTERS.SEASON': (state, payload) => {
    Vue.set(state.filters, 'season', payload);
  },

  'SET_LAYOUT.DRAWER': (state, payload) => {
    Vue.set(state.layout, 'drawer', payload);
  },
};

// export store
export default {
  namespaced: true,
  state: STATE,
  getters,
  mutations,
  actions,
};
