import { Mappers, Entities } from "@flightscope/baseball-stats";

class RankingStatMapper extends Mappers.MapperBase {
  // eslint-disable-next-line class-methods-use-this, this is so fucking stupid
  mapSingle(item) {
      return Entities.RankingStat.fromObject(item);
  }
}

export default RankingStatMapper;
