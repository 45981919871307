import { Model } from '@vuex-orm/core';

/**
 * @see {@link 'node_modules\@flightscope\dto-schema\baseball\entity\playertag.json'}
 */
export class RosterPlayer extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'rosterPlayers';

  static state() {
    return {
      fetching: false,
    };
  }

  static fields() {
    return {

    };
  }
}

export default RosterPlayer;
